// src/components/crm/support/TicketList.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, User, 
  Mail, Phone, Calendar, Tag, AlertCircle, 
  MessageSquare, Clock, MessageCircle, X
} from 'lucide-react';

const TicketList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);

  // Fetch tickets
  useEffect(() => {
    const fetchTickets = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(r => setTimeout(r, 800));
        
        // Mock ticket data
        const mockData = [
          {
            id: '1',
            subject: 'Cannot access account after password reset',
            customer: 'John Smith',
            customerEmail: 'john@example.com',
            company: 'Acme Corporation',
            status: 'new',
            priority: 'high',
            category: 'Account Access',
            assignedTo: 'Sarah Kim',
            createdAt: '2025-03-20T10:15:00Z',
            lastUpdated: '2025-03-20T10:15:00Z',
            messages: 2
          },
          {
            id: '2',
            subject: 'Product not working as expected',
            customer: 'Jane Doe',
            customerEmail: 'jane@example.com',
            company: 'Beta Industries',
            status: 'in_progress',
            priority: 'medium',
            category: 'Product Issue',
            assignedTo: 'Michael Johnson',
            createdAt: '2025-03-19T14:30:00Z',
            lastUpdated: '2025-03-20T09:45:00Z',
            messages: 4
          },
          {
            id: '3',
            subject: 'Need help with integration',
            customer: 'Robert Chen',
            customerEmail: 'robert@example.com',
            company: 'Gamma Technologies',
            status: 'waiting',
            priority: 'medium',
            category: 'Technical Support',
            assignedTo: 'David Chen',
            createdAt: '2025-03-18T09:20:00Z',
            lastUpdated: '2025-03-20T08:15:00Z',
            messages: 5
          },
          {
            id: '4',
            subject: 'Request for refund',
            customer: 'Emily Wilson',
            customerEmail: 'emily@example.com',
            company: 'Delta Corp',
            status: 'waiting',
            priority: 'high',
            category: 'Billing',
            assignedTo: 'Sarah Kim',
            createdAt: '2025-03-17T16:45:00Z',
            lastUpdated: '2025-03-19T14:20:00Z',
            messages: 3
          },
          {
            id: '5',
            subject: 'Feature request: Export to PDF',
            customer: 'Michael Brown',
            customerEmail: 'michael@example.com',
            company: 'Epsilon Systems',
            status: 'in_progress',
            priority: 'low',
            category: 'Feature Request',
            assignedTo: 'David Chen',
            createdAt: '2025-03-15T11:30:00Z',
            lastUpdated: '2025-03-18T16:10:00Z',
            messages: 6
          },
          {
            id: '6',
            subject: 'Invoice discrepancy',
            customer: 'Jennifer Garcia',
            customerEmail: 'jennifer@example.com',
            company: 'Zeta LLC',
            status: 'resolved',
            priority: 'medium',
            category: 'Billing',
            assignedTo: 'Michael Johnson',
            createdAt: '2025-03-10T13:15:00Z',
            lastUpdated: '2025-03-15T09:30:00Z',
            messages: 8,
            resolvedAt: '2025-03-15T09:30:00Z'
          },
          {
            id: '7',
            subject: 'Software installation issue',
            customer: 'David Wilson',
            customerEmail: 'david@example.com',
            company: 'Theta Co',
            status: 'closed',
            priority: 'medium',
            category: 'Technical Support',
            assignedTo: 'Sarah Kim',
            createdAt: '2025-03-08T10:00:00Z',
            lastUpdated: '2025-03-12T16:45:00Z',
            messages: 6,
            resolvedAt: '2025-03-12T16:45:00Z'
          }
        ];
        
        setTickets(mockData);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchTickets();
  }, []);

  // Get filtered tickets
  const getFilteredTickets = () => {
    let filtered = tickets;
    
    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(ticket => 
        ticket.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.customerEmail.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Filter by status
    if (filterStatus !== 'all') {
      filtered = filtered.filter(ticket => ticket.status === filterStatus);
    }
    
    return filtered;
  };

  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  // Get status display info
  const getStatusInfo = (status) => {
    switch (status) {
      case 'new':
        return { label: 'New', color: 'bg-blue-100 text-blue-800' };
      case 'in_progress':
        return { label: 'In Progress', color: 'bg-yellow-100 text-yellow-800' };
      case 'waiting':
        return { label: 'Waiting', color: 'bg-purple-100 text-purple-800' };
      case 'resolved':
        return { label: 'Resolved', color: 'bg-green-100 text-green-800' };
      case 'closed':
        return { label: 'Closed', color: 'bg-gray-100 text-gray-800' };
      default:
        return { label: status, color: 'bg-gray-100 text-gray-800' };
    }
  };

  // Get priority display info
  const getPriorityInfo = (priority) => {
    switch (priority) {
      case 'high':
        return { label: 'High', color: 'text-red-600' };
      case 'medium':
        return { label: 'Medium', color: 'text-orange-600' };
      case 'low':
        return { label: 'Low', color: 'text-green-600' };
      default:
        return { label: priority, color: 'text-gray-600' };
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search tickets by subject, customer, or company..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Status</option>
            <option value="new">New</option>
            <option value="in_progress">In Progress</option>
            <option value="waiting">Waiting</option>
            <option value="resolved">Resolved</option>
            <option value="closed">Closed</option>
          </select>
          
          <Link
            to="/crm/support/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
          >
            <PlusCircle size={18} className="mr-2" />
            Create Ticket
          </Link>
        </div>
      </div>

      {/* Empty State */}
      {tickets.length === 0 ? (
        <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
          <MessageCircle size={48} className="mx-auto text-gray-400 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No support tickets found</h3>
          <p className="text-gray-500 mb-6 max-w-md mx-auto">
            You haven't created any support tickets yet. Click the 'Create Ticket' button to get started.
          </p>
          <Link
            to="/crm/support/new"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
          >
            <PlusCircle size={16} className="mr-2" />
            Create Your First Ticket
          </Link>
        </div>
      ) : getFilteredTickets().length === 0 ? (
        <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
          <MessageCircle size={48} className="mx-auto text-gray-400 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No matching tickets</h3>
          <p className="text-gray-500 mb-6 max-w-md mx-auto">
            We couldn't find any tickets matching your search criteria. Try adjusting your search or filters.
          </p>
          <button
            onClick={() => { setSearchTerm(''); setFilterStatus('all'); }}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            Clear Filters
          </button>
        </div>
      ) : (
        /* Tickets List/Table */
        <div className="bg-white rounded-xl shadow-md overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ticket
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Customer
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Category
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Assigned To
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Last Updated
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {getFilteredTickets().map((ticket) => {
                  const statusInfo = getStatusInfo(ticket.status);
                  const priorityInfo = getPriorityInfo(ticket.priority);
                  
                  return (
                    <tr key={ticket.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4">
                        <div className="flex flex-col">
                          <div className="flex items-start">
                            <div className={`flex-shrink-0 h-5 w-5 rounded-full ${
                              ticket.priority === 'high' ? 'bg-red-500' :
                              ticket.priority === 'medium' ? 'bg-orange-500' : 'bg-green-500'
                            } mr-2 mt-0.5`} />
                            <div>
                              <div className="text-sm font-medium text-gray-900">{ticket.subject}</div>
                              <div className="text-xs text-gray-500 mt-1 flex items-center">
                                <MessageSquare size={12} className="mr-1" /> {ticket.messages} messages
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex flex-col">
                          <div className="text-sm font-medium text-gray-900">{ticket.customer}</div>
                          <div className="text-xs text-gray-500">{ticket.company}</div>
                          <div className="text-xs text-gray-500 flex items-center mt-1">
                            <Mail size={12} className="mr-1" /> {ticket.customerEmail}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusInfo.color}`}>
                          {statusInfo.label}
                        </span>
                        <div className="text-xs mt-1 font-medium flex items-center">
                          <AlertCircle size={12} className={`mr-1 ${priorityInfo.color}`} />
                          <span className={priorityInfo.color}>{priorityInfo.label} Priority</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                          {ticket.category}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {ticket.assignedTo}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex items-center">
                        <Clock size={14} className="mr-1 text-gray-400" /> {formatDate(ticket.lastUpdated)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link
                          to={`/crm/support/${ticket.id}`}
                          className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                        >
                          View <ChevronRight size={16} className="ml-1" />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketList;