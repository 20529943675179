// src/components/crm/customers/CustomerForm.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Save, AlertCircle, Building, User,
  Mail, Phone, Globe, MapPin, Tag, Users, Check
} from 'lucide-react';

const CustomerForm = ({ isEditing = false }) => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(isEditing);
  const [saving, setSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  
  const [formData, setFormData] = useState({
    name: '',
    type: 'business', // 'business' or 'individual'
    category: '',
    contactName: '',
    email: '',
    phone: '',
    website: '',
    address: '',
    notes: '',
    status: 'active',
    assignedTo: ''
  });
  
  // Staff members (for assignment)
  const [staffMembers, setStaffMembers] = useState([
    { id: '1', name: 'Michael Johnson' },
    { id: '2', name: 'Sarah Kim' },
    { id: '3', name: 'David Chen' }
  ]);
  
  // Categories
  const categoryOptions = [
    'Technology', 'Retail', 'Manufacturing', 'Healthcare', 
    'Education', 'Finance', 'Consulting', 'Real Estate', 'Other'
  ];
  
  // Fetch customer data if editing
  useEffect(() => {
    if (isEditing && customerId) {
      const fetchCustomerData = async () => {
        setLoading(true);
        try {
          // In a real app, this would be an API call
          await new Promise(resolve => setTimeout(resolve, 800));
          
          // Mock customer data
          const mockCustomer = {
            id: customerId,
            name: 'Acme Corporation',
            type: 'business',
            category: 'Technology',
            contactName: 'John Smith',
            email: 'john@acmecorp.com',
            phone: '+1 (555) 123-4567',
            website: 'www.acmecorp.com',
            address: '123 Business Ave, Suite 100, San Francisco, CA 94107',
            notes: 'Key account with long-term contract. Renewal coming up in October 2025.',
            status: 'active',
            assignedTo: '1'
          };
          
          setFormData(mockCustomer);
        } catch (error) {
          console.error('Error fetching customer:', error);
        } finally {
          setLoading(false);
        }
      };
      
      fetchCustomerData();
    }
  }, [isEditing, customerId]);
  
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle checkboxes
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!formData.name.trim()) {
      errors.name = 'Customer name is required';
    }
    
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    
    if (formData.type === 'business' && !formData.contactName.trim()) {
      errors.contactName = 'Contact name is required for business customers';
    }
    
    if (!formData.category) {
      errors.category = 'Category is required';
    }
    
    return errors;
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      
      // Scroll to first error
      const firstErrorElement = document.querySelector('.text-red-600');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      return;
    }
    
    setSaving(true);
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Navigate back to the customer list or detail page
      navigate(isEditing ? `/crm/customers/${customerId}` : '/crm/customers');
    } catch (error) {
      console.error('Error saving customer:', error);
      setFormErrors({ submit: 'An error occurred while saving. Please try again.' });
    } finally {
      setSaving(false);
    }
  };
  
  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }
  
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex items-center">
          <button 
            type="button"
            onClick={() => navigate('/crm/customers')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center mr-4"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back
          </button>
          <h2 className="text-lg font-medium text-gray-900">
            {isEditing ? 'Edit Customer' : 'Add New Customer'}
          </h2>
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        {/* Form Error Message */}
        {formErrors.submit && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
          <div className="flex">
            <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
            <p>{formErrors.submit}</p>
          </div>
        </div>
      )}
      
      {/* Customer Type Selection */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Customer Type <span className="text-red-500">*</span>
        </label>
        <div className="flex space-x-4">
          <button
            type="button"
            onClick={() => handleInputChange({ target: { name: 'type', value: 'business' } })}
            className={`flex items-center px-4 py-2 border rounded-lg ${
              formData.type === 'business' 
                ? 'bg-[#190192] text-white border-[#190192]' 
                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
            }`}
          >
            <Building size={18} className="mr-2" /> 
            Business
          </button>
          <button
            type="button"
            onClick={() => handleInputChange({ target: { name: 'type', value: 'individual' } })}
            className={`flex items-center px-4 py-2 border rounded-lg ${
              formData.type === 'individual' 
                ? 'bg-[#190192] text-white border-[#190192]' 
                : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
            }`}
          >
            <User size={18} className="mr-2" /> 
            Individual
          </button>
        </div>
      </div>
      
      {/* Basic Information */}
      <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        {/* Customer Name */}
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
            {formData.type === 'business' ? 'Business Name' : 'Full Name'} <span className="text-red-500">*</span>
          </label>
          <input
            id="name"
            name="name"
            type="text"
            className={`block w-full rounded-lg border ${
              formErrors.name ? 'border-red-300' : 'border-gray-300'
            } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
            value={formData.name}
            onChange={handleInputChange}
          />
          {formErrors.name && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.name}
            </p>
          )}
        </div>
        
        {/* Category */}
        <div>
          <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
            Category <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Tag size={18} className="text-gray-400" />
            </div>
            <select
              id="category"
              name="category"
              className={`pl-10 block w-full rounded-lg border ${
                formErrors.category ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              value={formData.category}
              onChange={handleInputChange}
            >
              <option value="">Select Category</option>
              {categoryOptions.map((category) => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          {formErrors.category && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.category}
            </p>
          )}
        </div>
      </div>
      
      {/* Contact Name (for business only) */}
      {formData.type === 'business' && (
        <div className="mb-6">
          <label htmlFor="contactName" className="block text-sm font-medium text-gray-700 mb-1">
            Primary Contact Name <span className="text-red-500">*</span>
          </label>
          <input
            id="contactName"
            name="contactName"
            type="text"
            className={`block w-full rounded-lg border ${
              formErrors.contactName ? 'border-red-300' : 'border-gray-300'
            } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
            value={formData.contactName}
            onChange={handleInputChange}
          />
          {formErrors.contactName && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.contactName}
            </p>
          )}
        </div>
      )}
      
      {/* Contact Information */}
      <h3 className="text-lg font-medium text-gray-900 mb-4">Contact Information</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        {/* Email */}
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
            Email <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Mail size={18} className="text-gray-400" />
            </div>
            <input
              id="email"
              name="email"
              type="email"
              className={`pl-10 block w-full rounded-lg border ${
                formErrors.email ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          {formErrors.email && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.email}
            </p>
          )}
        </div>
        
        {/* Phone */}
        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
            Phone
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Phone size={18} className="text-gray-400" />
            </div>
            <input
              id="phone"
              name="phone"
              type="text"
              className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </div>
        </div>
        
        {/* Website */}
        <div>
          <label htmlFor="website" className="block text-sm font-medium text-gray-700 mb-1">
            Website
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Globe size={18} className="text-gray-400" />
            </div>
            <input
              id="website"
              name="website"
              type="text"
              className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.website}
              onChange={handleInputChange}
              placeholder="www.example.com"
            />
          </div>
        </div>
        
        {/* Address */}
        <div>
          <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
            Address
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MapPin size={18} className="text-gray-400" />
            </div>
            <input
              id="address"
              name="address"
              type="text"
              className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      
      {/* Additional Information */}
      <h3 className="text-lg font-medium text-gray-900 mb-4">Additional Information</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        {/* Status */}
        <div>
          <label htmlFor="status" className="block text-sm font-medium text-gray-700 mb-1">
            Status
          </label>
          <select
            id="status"
            name="status"
            className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
            value={formData.status}
            onChange={handleInputChange}
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="lead">Lead</option>
            <option value="prospect">Prospect</option>
          </select>
        </div>
        
        {/* Assigned To */}
        <div>
          <label htmlFor="assignedTo" className="block text-sm font-medium text-gray-700 mb-1">
            Assigned To
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Users size={18} className="text-gray-400" />
            </div>
            <select
              id="assignedTo"
              name="assignedTo"
              className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.assignedTo}
              onChange={handleInputChange}
            >
              <option value="">Unassigned</option>
              {staffMembers.map((staff) => (
                <option key={staff.id} value={staff.id}>{staff.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      
      {/* Notes */}
      <div className="mb-6">
        <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
          Notes
        </label>
        <textarea
          id="notes"
          name="notes"
          rows="4"
          className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
          value={formData.notes}
          onChange={handleInputChange}
        />
      </div>
      
      {/* Form Actions */}
      <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
        <button
          type="button"
          onClick={() => navigate('/crm/customers')}
          className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
          disabled={saving}
        >
          Cancel
        </button>
        
        <button
          type="submit"
          disabled={saving}
          className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
            saving ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
          }`}
        >
          {saving ? (
            <>
              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Saving...
            </>
          ) : (
            <>
              <Save size={18} className="mr-2" />
              {isEditing ? 'Update Customer' : 'Create Customer'}
            </>
          )}
        </button>
      </div>
    </form>
  </div>
);
};

export default CustomerForm;