// src/components/subscription/DiscountCountdown.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Clock } from 'lucide-react';

const DiscountCountdown = () => {
  const navigate = useNavigate();
  const [remainingSpots, setRemainingSpots] = useState(89);
  const [isVisible, setIsVisible] = useState(true);
  
  // Simulate spot count decreasing
  useEffect(() => {
    // Check if the banner has been dismissed
    const hasDismissed = localStorage.getItem('dismissedDiscountBanner');
    if (hasDismissed) {
      setIsVisible(false);
      return;
    }
    
    // Fetch remaining spots from API (simulated)
    // In a real app, you would fetch from your database
    const fetchRemainingSpots = async () => {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      setRemainingSpots(89);
    };
    
    fetchRemainingSpots();
    
    // Optionally, you could decrease the count in real-time to create urgency
    const interval = setInterval(() => {
      setRemainingSpots(prev => {
        if (prev <= 1) {
          clearInterval(interval);
          return 1;
        }
        return Math.max(1, prev - Math.floor(Math.random() * 2));
      });
    }, 60000); // Update every minute
    
    return () => clearInterval(interval);
  }, []);
  
  const dismissBanner = () => {
    setIsVisible(false);
    localStorage.setItem('dismissedDiscountBanner', 'true');
  };
  
  if (!isVisible) return null;
  
  return (
    <div className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white py-1 px-4">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <Clock className="h-4 w-4 mr-2" />
          <span className="text-sm">
            Launch offer: <strong>50% OFF</strong> for the first year! Only <strong>{remainingSpots}</strong> spots left
          </span>
        </div>
        <div className="flex items-center">
          <button
            onClick={() => navigate('/pricing')}
            className="text-xs bg-white text-orange-600 px-2 py-1 rounded font-medium mr-3 hover:bg-orange-100"
          >
            Get 50% Off
          </button>
          <button
            onClick={dismissBanner}
            className="text-xs text-white hover:text-orange-100"
          >
            ✕
          </button>
        </div>
      </div>
    </div>
  );
};

export default DiscountCountdown;