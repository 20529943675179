// src/pages/subscription/SubscriptionManagement.jsx (updated for currency and discounts)
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  CreditCard, Calendar, AlertCircle, CheckCircle, 
  Download, Settings, ArrowRight, Tag, Globe, DollarSign
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

const SubscriptionManagement = () => {
  const { subscription, trialDaysLeft, isTrialing, cancelSubscription } = useAuth();
  const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);
  const navigate = useNavigate();
  
  if (!subscription) {
    // No subscription - redirect to pricing
    navigate('/subscription/pricing');
    return null;
  }
  
  const formatDate = (date) => {
    if (!date) return 'N/A';
    const d = new Date(date);
    return d.toLocaleDateString('en-US', { 
      day: 'numeric', 
      month: 'long', 
      year: 'numeric' 
    });
  };
  
  // Format currency
  const formatCurrency = (amount) => {
    if (subscription.currency === 'ZAR') {
      return new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
        currencyDisplay: 'symbol'
      }).format(amount);
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol'
      }).format(amount);
    }
  };
  
  // Plan details (should come from database)
  const planDetails = {
    basic: { name: 'Basic' },
    pro: { name: 'Professional' },
    enterprise: { name: 'Enterprise' }
  };
  
  const currentPlan = planDetails[subscription.planId] || { name: 'Unknown Plan' };
  
  // Check if subscription has an active discount
  const hasDiscount = subscription.discountType && subscription.discountExpiryDate && new Date(subscription.discountExpiryDate) > new Date();
  
  // Calculate discount percentage
  const getDiscountPercentage = () => {
    if (!hasDiscount) return 0;
    
    if (subscription.discountType === 'launch') {
      return 50;
    }
    
    // Add other discount types as needed
    return 0;
  };
  
  // Handle subscription cancellation
  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription();
      setIsConfirmingCancel(false);
    } catch (error) {
      console.error("Error canceling subscription:", error);
    }
  };
  
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden max-w-4xl mx-auto">
      <div className="px-6 py-4 bg-gray-50 border-b">
        <h2 className="text-xl font-medium text-gray-900">Subscription Management</h2>
      </div>
      
      {/* Trial Banner */}
      {isTrialing && (
        <div className="bg-blue-50 border-l-4 border-blue-500 p-4 m-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <Calendar className="h-5 w-5 text-blue-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                You're currently on a free trial. <strong>{trialDaysLeft} days</strong> remaining.
              </p>
              <div className="mt-2">
                <button 
                  onClick={() => navigate('/subscription/payment')}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                >
                  Add Payment Method
                  <ArrowRight className="ml-2 h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      
      <div className="p-6 space-y-6">
        {/* Current Plan */}
        <div className="bg-gray-50 rounded-lg p-4">
          <h3 className="text-md font-medium text-gray-900 mb-2">Current Plan</h3>
          <div className="flex items-center justify-between">
            <div>
              <p className="text-2xl font-semibold text-[#190192]">{currentPlan.name}</p>
              <p className="text-gray-600">
                {formatCurrency(subscription.price)}
                /{subscription.billingInterval}
              </p>
              
              {hasDiscount && (
                <div className="mt-1 flex items-center">
                  <Tag className="h-4 w-4 text-green-600 mr-1" />
                  <span className="text-sm text-green-600 font-medium">
                    {getDiscountPercentage()}% discount applied until {formatDate(subscription.discountExpiryDate)}
                  </span>
                </div>
              )}
            </div>
            <button
              onClick={() => navigate('/subscription/pricing')}
              className="px-4 py-2 border border-gray-300 text-[#190192] rounded-md hover:bg-gray-50"
            >
              Change Plan
            </button>
          </div>
        </div>
        
        {/* Subscription Details */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-md font-medium text-gray-900 mb-2">Subscription Details</h3>
            <dl className="space-y-2">
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">Status</dt>
                <dd className={`text-sm font-medium ${
                  subscription.status === 'active' ? 'text-green-600' :
                  subscription.status === 'trialing' ? 'text-blue-600' :
                  'text-gray-900'
                }`}>
                  {subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">Start Date</dt>
                <dd className="text-sm text-gray-900">
                  {isTrialing ? 
                    formatDate(subscription.trialStartDate) :
                    formatDate(subscription.subscriptionStartDate)
                  }
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">
                  {isTrialing ? 'Trial End Date' : 'Next Billing Date'}
                </dt>
                <dd className="text-sm text-gray-900">
                  {isTrialing ? 
                    formatDate(subscription.trialEndDate) :
                    formatDate(subscription.subscriptionEndDate)
                  }
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">Currency</dt>
                <dd className="text-sm text-gray-900 flex items-center">
                  <Globe className="h-3 w-3 mr-1" />
                  {subscription.currency || 'ZAR'}
                </dd>
              </div>
              <div className="flex justify-between">
                <dt className="text-sm text-gray-500">Billing Interval</dt>
                <dd className="text-sm text-gray-900">
                  {subscription.billingInterval === 'annual' ? 'Yearly' : 'Monthly'}
                </dd>
              </div>
            </dl>
          </div>
          
          <div>
            <h3 className="text-md font-medium text-gray-900 mb-2">Payment Method</h3>
            {subscription.paymentMethod ? (
              <div className="flex items-center bg-white rounded-md border p-3">
                <CreditCard className="h-8 w-8 text-gray-400 mr-3" />
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    •••• •••• •••• {subscription.paymentMethod.lastFour}
                  </p>
                  <p className="text-xs text-gray-500">
                    Expires {subscription.paymentMethod.expiryMonth}/{subscription.paymentMethod.expiryYear}
                  </p>
                </div>
                <button className="ml-auto text-[#190192] text-sm">
                  Update
                </button>
              </div>
            ) : (
              <div className="bg-white rounded-md border p-4 text-center">
                <p className="text-sm text-gray-500 mb-3">No payment method on file</p>
                <button
                  onClick={() => navigate('/subscription/payment')}
                  className="px-4 py-2 bg-[#190192] text-white rounded-md hover:bg-[#2C0DB5]"
                >
                  Add Payment Method
                </button>
              </div>
            )}
          </div>
        </div>
        
        {/* Price Breakdown */}
        {hasDiscount && (
          <div className="bg-green-50 rounded-lg p-4 border border-green-100">
            <h3 className="text-md font-medium text-gray-900 mb-2">Price Breakdown</h3>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-sm text-gray-600">Original Price:</span>
                <span className="text-sm text-gray-600">
                  {formatCurrency(subscription.originalPrice)}/{subscription.billingInterval}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-green-600 font-medium">
                  {subscription.discountType.charAt(0).toUpperCase() + subscription.discountType.slice(1)} Discount:
                </span>
                <span className="text-sm text-green-600 font-medium">
                  -{formatCurrency(subscription.originalPrice - subscription.price)}
                </span>
              </div>
              <div className="pt-2 border-t border-green-200 flex justify-between">
                <span className="text-sm font-medium text-gray-900">Your Price:</span>
                <span className="text-sm font-medium text-gray-900">
                  {formatCurrency(subscription.price)}/{subscription.billingInterval}
                </span>
              </div>
            </div>
          </div>
        )}
        
        {/* Invoices */}
        <div>
          <h3 className="text-md font-medium text-gray-900 mb-2">Billing History</h3>
          <div className="bg-white border rounded-md">
            <div className="flex items-center justify-between bg-gray-50 px-4 py-2 border-b">
              <div className="text-sm font-medium text-gray-500">Invoice</div>
              <div className="text-sm font-medium text-gray-500">Amount</div>
              <div className="text-sm font-medium text-gray-500">Status</div>
              <div className="text-sm font-medium text-gray-500">Date</div>
              <div className="text-sm font-medium text-gray-500">Download</div>
            </div>
            
            {/* Sample invoice */}
            <div className="divide-y">
              <div className="flex items-center justify-between px-4 py-3">
                <div className="text-sm text-gray-900">INV-001</div>
                <div className="text-sm text-gray-900">{formatCurrency(subscription.price)}</div>
                <div className="text-sm">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Paid
                  </span>
                </div>
                <div className="text-sm text-gray-900">Mar 1, 2025</div>
                <div>
                  <button className="text-[#190192]">
                    <Download className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Cancel Subscription */}
        <div className="border-t pt-6">
          {isConfirmingCancel ? (
            <div className="bg-red-50 border rounded-md p-4">
              <h4 className="text-red-800 font-medium mb-2">Confirm Cancellation</h4>
              <p className="text-sm text-red-700 mb-4">
                Are you sure you want to cancel your subscription? You'll lose access to premium features at the end of your billing period.
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setIsConfirmingCancel(false)}
                  className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                >
                  Keep Subscription
                </button>
                <button
                  onClick={handleCancelSubscription}
                  className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                >
                  Cancel Subscription
                </button>
              </div>
            </div>
          ) : (
            <button
              onClick={() => setIsConfirmingCancel(true)}
              className="text-red-600 hover:text-red-800 text-sm"
            >
              Cancel subscription
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionManagement;