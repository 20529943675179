// src/components/communication/EmailSender.js
import React, { useState, useEffect } from 'react';
import { 
  Mail, Send, User, Users, Tag, UploadCloud, X, AlertCircle,
  Loader, Check, File, ChevronDown, Info, Eye, Settings, Link, RefreshCw
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { db } from '../../../firebase/config';
import { 
  collection, doc, getDoc, query, where, getDocs, 
  addDoc, serverTimestamp
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const EmailSender = () => {
  const { currentUser, userProfile } = useAuth();
  
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  const [templates, setTemplates] = useState([]);
  const [businessProfiles, setBusinessProfiles] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateContent, setTemplateContent] = useState('');
  const [showTemplatePreview, setShowTemplatePreview] = useState(false);
  
  const [emailData, setEmailData] = useState({
    subject: '',
    to: '',
    cc: '',
    bcc: '',
    recipients: [],
    businessId: '',
    templateId: '',
    content: '',
    attachments: []
  });
  
  // Load business profiles and templates
  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) return;
      
      setLoading(true);
      setError(null);
      
      try {
        // Fetch business profiles
        const businessesRef = collection(db, 'businesses');
        const businessQuery = query(businessesRef, where('createdBy', '==', currentUser.uid));
        const businessSnapshot = await getDocs(businessQuery);
        
        const businesses = [];
        businessSnapshot.forEach(doc => {
          businesses.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setBusinessProfiles(businesses);
        
        // If user has businesses, auto-select the first one
        if (businesses.length > 0) {
          setEmailData(prev => ({
            ...prev,
            businessId: businesses[0].id
          }));
          
          // Fetch templates for this business
          const templatesRef = collection(db, 'templates');
          const templatesQuery = query(
            templatesRef,
            where('businessId', '==', businesses[0].id),
            where('type', '==', 'email'),
            where('status', '==', 'active')
          );
          
          const templatesSnapshot = await getDocs(templatesQuery);
          const templatesList = [];
          
          templatesSnapshot.forEach(doc => {
            templatesList.push({
              id: doc.id,
              ...doc.data()
            });
          });
          
          setTemplates(templatesList);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load business data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [currentUser]);
  
  // Load template content when template is selected
  useEffect(() => {
    const loadTemplate = async () => {
      if (!emailData.templateId) {
        setSelectedTemplate(null);
        setTemplateContent('');
        return;
      }
      
      try {
        setLoading(true);
        setError(null);
        
        // Find template in our already loaded templates
        const template = templates.find(t => t.id === emailData.templateId);
        
        if (template) {
          setSelectedTemplate(template);
          setTemplateContent(template.content);
          
          // Auto-fill subject if it's empty
          if (!emailData.subject) {
            setEmailData(prev => ({
              ...prev,
              subject: template.subject
            }));
          }
        } else {
          // If not found (rare case), fetch it directly
          const templateDoc = await getDoc(doc(db, 'templates', emailData.templateId));
          
          if (templateDoc.exists()) {
            const templateData = templateDoc.data();
            setSelectedTemplate(templateData);
            setTemplateContent(templateData.content);
            
            if (!emailData.subject) {
              setEmailData(prev => ({
                ...prev,
                subject: templateData.subject
              }));
            }
          } else {
            setError("Template not found");
            setSelectedTemplate(null);
            setTemplateContent('');
          }
        }
      } catch (err) {
        console.error("Error loading template:", err);
        setError("Failed to load template. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    
    loadTemplate();
  }, [emailData.templateId, templates]);
  
  // Load templates when business changes
  useEffect(() => {
    const loadTemplatesForBusiness = async () => {
      if (!emailData.businessId) return;
      
      try {
        setLoading(true);
        setError(null);
        
        // Fetch templates for selected business
        const templatesRef = collection(db, 'templates');
        const templatesQuery = query(
          templatesRef,
          where('businessId', '==', emailData.businessId),
          where('type', '==', 'email'),
          where('status', '==', 'active')
        );
        
        const templatesSnapshot = await getDocs(templatesQuery);
        const templatesList = [];
        
        templatesSnapshot.forEach(doc => {
          templatesList.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setTemplates(templatesList);
        
        // Reset template selection when business changes
        setEmailData(prev => ({
          ...prev,
          templateId: ''
        }));
        setSelectedTemplate(null);
        setTemplateContent('');
      } catch (err) {
        console.error("Error loading templates:", err);
        setError("Failed to load templates. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    
    loadTemplatesForBusiness();
  }, [emailData.businessId]);
  
  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setEmailData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear messages when user makes changes
    setError(null);
    setSuccess(null);
  };
  
  // Handle business change
  const handleBusinessChange = (e) => {
    const businessId = e.target.value;
    setEmailData(prev => ({
      ...prev,
      businessId
    }));
  };
  
  // Handle template change
  const handleTemplateChange = (e) => {
    const templateId = e.target.value;
    setEmailData(prev => ({
      ...prev,
      templateId
    }));
  };
  
  // Parse recipient string into array
  const parseRecipients = (recipientString) => {
    if (!recipientString) return [];
    
    return recipientString
      .split(',')
      .map(email => email.trim())
      .filter(email => email && /\S+@\S+\.\S+/.test(email));
  };
  
  // Replace template variables with values
  const processTemplateContent = (content, recipientEmail) => {
    if (!content) return '';
    
    // Basic variable replacement - in a real app, you'd pull these from a recipient database
    let processedContent = content;
    
    // Extract recipient name from email
    const recipientName = recipientEmail.split('@')[0].replace(/\./g, ' ');
    const capitalizedName = recipientName
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    
    // Replace variables
    processedContent = processedContent.replace(/\{\{name\}\}/g, capitalizedName);
    processedContent = processedContent.replace(/\{\{email\}\}/g, recipientEmail);
    processedContent = processedContent.replace(/\{\{date\}\}/g, new Date().toLocaleDateString());
    
    // In a real app, you would replace more variables from your database
    
    return processedContent;
  };
  // Continuing src/components/communication/EmailSender.js

  // Send the email
  const handleSendEmail = async () => {
    try {
      // Validate inputs
      if (!emailData.businessId) {
        setError("Please select a business profile");
        return;
      }
      
      if (!emailData.subject) {
        setError("Please enter an email subject");
        return;
      }
      
      // Parse and validate recipients
      const toRecipients = parseRecipients(emailData.to);
      const ccRecipients = parseRecipients(emailData.cc);
      const bccRecipients = parseRecipients(emailData.bcc);
      
      if (toRecipients.length === 0) {
        setError("Please enter at least one recipient email");
        return;
      }
      
      // Get selected business profile for sender info
      const businessProfile = businessProfiles.find(b => b.id === emailData.businessId);
      
      if (!businessProfile) {
        setError("Selected business profile not found");
        return;
      }
      
      if (!businessProfile.emailSettings || !businessProfile.emailSettings.senderEmail) {
        setError("Business email settings are not configured");
        return;
      }
      
      // Check sender email verification
      if (businessProfile.emailSettings.verificationStatus !== 'verified') {
        setError("Sender email has not been verified. Please verify it in business settings.");
        return;
      }
      
      // Get content - either from template or custom content
      let emailContent = emailData.content;
      if (emailData.templateId && templateContent) {
        emailContent = templateContent;
      }
      
      if (!emailContent) {
        setError("Email content cannot be empty");
        return;
      }
      
      // Start sending
      setSending(true);
      setError(null);
      setSuccess(null);
      
      // Generate tracking ID for this email
      const trackingId = `email_${Date.now()}_${Math.random().toString(36).substring(2, 15)}`;
      
      // For each recipient, process template and send personalized email
      for (const recipient of toRecipients) {
        // Process template variables for this recipient
        const personalizedContent = processTemplateContent(emailContent, recipient);
        
        // Construct email data
        const emailToSend = {
          to: recipient,
          from: businessProfile.emailSettings.senderEmail,
          subject: emailData.subject,
          html: personalizedContent,
          businessProfile: {
            name: businessProfile.name || businessProfile.emailSettings.senderName,
            id: businessProfile.id
          },
          trackingId: `${trackingId}_${toRecipients.indexOf(recipient)}`
        };
        
        // Add CC and BCC if present
        if (ccRecipients.length > 0) {
          emailToSend.cc = ccRecipients;
        }
        
        if (bccRecipients.length > 0) {
          emailToSend.bcc = bccRecipients;
        }
        
        // Add reply-to if configured
        if (businessProfile.emailSettings.replyToEmail) {
          emailToSend.replyTo = businessProfile.emailSettings.replyToEmail;
        }
        
        // Add default footer if configured
        if (businessProfile.emailSettings.defaultFooter) {
          emailToSend.html += `<div class="email-footer">${businessProfile.emailSettings.defaultFooter}</div>`;
        }
        
        // Send the email
        const sendEmail = async (businessId, emailData) => {
          try {
            const functions = getFunctions();
            const sendEmailFunction = httpsCallable(functions, 'sendEmail');
            
            const result = await sendEmailFunction({
              businessId,
              emailData
            });
            
            return result.data;
          } catch (error) {
            console.error('Error sending email:', error);
            throw error;
          }
        };
        
        // Log email record to Firestore
        await addDoc(collection(db, 'emailLogs'), {
          businessId: businessProfile.id,
          templateId: emailData.templateId || null,
          subject: emailData.subject,
          recipients: {
            to: [recipient],
            cc: ccRecipients,
            bcc: bccRecipients
          },
          senderId: currentUser.uid,
          senderEmail: businessProfile.emailSettings.senderEmail,
          senderName: businessProfile.emailSettings.senderName || businessProfile.name,
          trackingId: emailToSend.trackingId,
          status: 'sent',
          sentAt: serverTimestamp(),
          opened: false,
          clicked: false
        });
      }
      
      // Show success message
      setSuccess(`Email sent successfully to ${toRecipients.length} recipient${toRecipients.length > 1 ? 's' : ''}`);
      
      // Reset form (except business selection)
      setEmailData(prev => ({
        ...prev,
        subject: '',
        to: '',
        cc: '',
        bcc: '',
        templateId: '',
        content: '',
        attachments: []
      }));
      
      setSelectedTemplate(null);
      setTemplateContent('');
      
    } catch (err) {
      console.error("Error sending email:", err);
      setError(`Failed to send email: ${err.message}`);
    } finally {
      setSending(false);
    }
  };
  
  return (
    <div className="bg-white rounded-xl shadow-md p-6">
      <h2 className="text-xl font-medium text-gray-900 mb-6">Send Email</h2>
      
      {/* Messages */}
      {error && (
        <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700 flex items-start">
          <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}
      
      {success && (
        <div className="mb-6 bg-green-50 border-l-4 border-green-500 p-4 text-green-700 flex items-start">
          <Check className="h-5 w-5 mr-2 flex-shrink-0" />
          <p>{success}</p>
        </div>
      )}
      
      <div className="space-y-6">
        {/* Business Profile Selection */}
        <div>
          <label htmlFor="businessId" className="block text-sm font-medium text-gray-700 mb-1">
            Send As <span className="text-red-500">*</span>
          </label>
          <div className="flex">
            <select
              id="businessId"
              name="businessId"
              value={emailData.businessId}
              onChange={handleBusinessChange}
              className="block w-full rounded-l-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
            >
              <option value="">Select a business profile</option>
              {businessProfiles.map(profile => (
                <option key={profile.id} value={profile.id}>
                  {profile.name || 'Unnamed Business'}
                </option>
              ))}
            </select>
            
            <Link
              to={`/business/email-settings/${emailData.businessId}`}
              className={`px-4 py-2 rounded-r-lg text-white ${
                !emailData.businessId ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
              } flex items-center`}
              onClick={e => {
                if (!emailData.businessId) {
                  e.preventDefault();
                }
              }}
            >
              <Settings className="h-4 w-4 mr-1" />
              Settings
            </Link>
          </div>
          
          {emailData.businessId && businessProfiles.length > 0 && (
            <div className="mt-2">
              {(() => {
                const business = businessProfiles.find(b => b.id === emailData.businessId);
                if (business && business.emailSettings) {
                  return (
                    <div className="text-sm flex items-center">
                      <Mail className="h-4 w-4 mr-1 text-gray-400" />
                      <span className="text-gray-500 mr-1">From:</span>
                      <span className="font-medium">
                        {business.emailSettings.senderName} &lt;{business.emailSettings.senderEmail}&gt;
                      </span>
                      
                      {business.emailSettings.verificationStatus !== 'verified' && (
                        <span className="ml-2 text-xs bg-yellow-100 text-yellow-800 px-2 py-0.5 rounded-full">
                          Not Verified
                        </span>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <p className="text-sm text-amber-600 flex items-center">
                      <Info className="h-4 w-4 mr-1" />
                      Email settings not configured for this business
                    </p>
                  );
                }
              })()}
            </div>
          )}
        </div>
        
        {/* Email Template Selection */}
        <div>
          <label htmlFor="templateId" className="block text-sm font-medium text-gray-700 mb-1">
            Email Template
          </label>
          <select
            id="templateId"
            name="templateId"
            value={emailData.templateId}
            onChange={handleTemplateChange}
            className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
            disabled={!emailData.businessId}
          >
            <option value="">Select a template (optional)</option>
            {templates.map(template => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
          
          {selectedTemplate && (
            <div className="mt-2 flex items-center">
              <button
                type="button"
                onClick={() => setShowTemplatePreview(!showTemplatePreview)}
                className="text-sm text-[#190192] hover:text-[#2C0DB5] flex items-center"
              >
                <Eye className="h-4 w-4 mr-1" />
                {showTemplatePreview ? 'Hide Preview' : 'Preview Template'}
              </button>
            </div>
          )}
          
          {showTemplatePreview && selectedTemplate && (
            <div className="mt-4 p-4 border border-gray-200 rounded-lg bg-gray-50">
              <div className="bg-white p-4 rounded border">
                <h3 className="text-lg font-medium mb-2">{selectedTemplate.subject}</h3>
                <div 
                  className="prose prose-sm max-w-none"
                  dangerouslySetInnerHTML={{ __html: templateContent }}
                />
              </div>
            </div>
          )}
        </div>
        
        {/* Email Subject */}
        <div>
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
            Subject <span className="text-red-500">*</span>
          </label>
          <input
            id="subject"
            name="subject"
            type="text"
            value={emailData.subject}
            onChange={handleInputChange}
            className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
            placeholder="Enter email subject"
            disabled={!emailData.businessId}
          />
        </div>
        
        {/* Recipients */}
        <div className="space-y-4">
          <div>
            <label htmlFor="to" className="block text-sm font-medium text-gray-700 mb-1">
              To <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <User className="h-4 w-4 text-gray-400" />
              </div>
              <input
                id="to"
                name="to"
                type="text"
                value={emailData.to}
                onChange={handleInputChange}
                className="pl-10 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                placeholder="recipient@example.com, another@example.com"
                disabled={!emailData.businessId}
              />
            </div>
            <p className="mt-1 text-xs text-gray-500">
              Separate multiple email addresses with commas
            </p>
          </div>
          
          <div>
            <label htmlFor="cc" className="block text-sm font-medium text-gray-700 mb-1">
              CC
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Users className="h-4 w-4 text-gray-400" />
              </div>
              <input
                id="cc"
                name="cc"
                type="text"
                value={emailData.cc}
                onChange={handleInputChange}
                className="pl-10 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                placeholder="cc@example.com, cc2@example.com"
                disabled={!emailData.businessId}
              />
            </div>
          </div>
          
          <div>
            <label htmlFor="bcc" className="block text-sm font-medium text-gray-700 mb-1">
              BCC
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Users className="h-4 w-4 text-gray-400" />
              </div>
              <input
                id="bcc"
                name="bcc"
                type="text"
                value={emailData.bcc}
                onChange={handleInputChange}
                className="pl-10 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                placeholder="bcc@example.com, bcc2@example.com"
                disabled={!emailData.businessId}
              />
            </div>
          </div>
        </div>
        
        {/* Email Content - only shown if no template is selected */}
        {!emailData.templateId && (
          <div>
            <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-1">
              Email Content <span className="text-red-500">*</span>
            </label>
            <textarea
              id="content"
              name="content"
              rows="10"
              value={emailData.content}
              onChange={handleInputChange}
              className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              placeholder="Enter your email content here..."
              disabled={!emailData.businessId || !!emailData.templateId}
            />
            <p className="mt-1 text-xs text-gray-500">
              HTML formatting is supported
            </p>
          </div>
        )}
        
        {/* Attachments - simplified for now */}
        <div>
          <div className="flex justify-between items-center mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Attachments
            </label>
            <button
              type="button"
              className={`text-sm text-[#190192] hover:text-[#2C0DB5] flex items-center ${
                !emailData.businessId ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={!emailData.businessId}
            >
              <UploadCloud className="h-4 w-4 mr-1" />
              Add Attachment
            </button>
          </div>
          
          <div className="border border-dashed border-gray-300 rounded-lg p-4 bg-gray-50">
            <p className="text-center text-gray-500 text-sm">
              No attachments added yet
            </p>
          </div>
          <p className="mt-1 text-xs text-gray-500">
            Max file size: 10MB. Supported formats: PDF, DOC, JPG, PNG
          </p>
        </div>
      </div>
      
      {/* Send Button */}
      <div className="mt-8 flex justify-end">
        <button
          type="button"
          onClick={handleSendEmail}
          disabled={sending || !emailData.businessId}
          className={`px-8 py-3 rounded-lg text-white ${
            sending || !emailData.businessId ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
          } flex items-center`}
        >
          {sending ? (
            <>
              <Loader className="animate-spin h-5 w-5 mr-2" />
              Sending...
            </>
          ) : (
            <>
              <Send className="h-5 w-5 mr-2" />
              Send Email
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default EmailSender;