// src/components/crm/customers/CustomerDetail.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Download, Trash2, User, 
  Mail, Phone, Globe, Building, Calendar, DollarSign,
  MapPin, FileText, ShoppingCart, MessageSquare, 
  PlusCircle, ChevronRight, Users, X
} from 'lucide-react';

const CustomerDetail = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Fetch customer details
  useEffect(() => {
    const fetchCustomerDetails = async () => {
      setLoading(true);
      try {
        // In a real app, this would be an API call
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock customer data
        const mockCustomer = {
          id: customerId,
          name: 'Acme Corporation',
          type: 'business',
          category: 'Technology',
          contactName: 'John Smith',
          email: 'john@acmecorp.com',
          phone: '+1 (555) 123-4567',
          website: 'www.acmecorp.com',
          status: 'active',
          lastContact: '2025-03-15',
          totalRevenue: 24500,
          address: '123 Business Ave, Suite 100, San Francisco, CA 94107',
          notes: 'Key account with long-term contract. Renewal coming up in October 2025.',
          assignedTo: 'Michael Johnson',
          createdAt: '2023-11-05',
          updatedAt: '2025-03-15',
          transactions: [
            { id: '1', date: '2025-03-10', type: 'sale', amount: 5000, description: 'Software License - Annual' },
            { id: '2', date: '2025-02-15', type: 'sale', amount: 1200, description: 'Consulting Services' },
            { id: '3', date: '2025-01-20', type: 'sale', amount: 3500, description: 'Hardware Purchase' }
          ],
          interactions: [
            { id: '1', date: '2025-03-15', type: 'email', summary: 'Follow-up on recent purchase', staff: 'Sarah Kim' },
            { id: '2', date: '2025-03-01', type: 'meeting', summary: 'Quarterly business review', staff: 'Michael Johnson' },
            { id: '3', date: '2025-02-10', type: 'call', summary: 'Discussion about new requirements', staff: 'David Chen' }
          ],
          documents: [
            { id: '1', name: 'Service Agreement.pdf', type: 'contract', uploadedAt: '2024-12-10' },
            { id: '2', name: 'Requirements Specification.docx', type: 'specification', uploadedAt: '2025-01-15' }
          ]
        };
        
        setCustomer(mockCustomer);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerDetails();
  }, [customerId]);

  // Handle delete
  const handleDelete = async () => {
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Navigate back to the customers list
      navigate('/crm/customers');
    } catch (error) {
      console.error('Error deleting customer:', error);
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!customer) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Customer not found</h3>
          <p className="text-gray-500 mb-6">The customer you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/crm/customers')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Customers
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/crm/customers"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Customers
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/crm/customers/edit/${customerId}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Customer Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className="w-16 h-16 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0 text-purple-800">
              {customer.type === 'business' ? 
                <Building size={32} /> : 
                <User size={32} />
              }
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{customer.name}</h1>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  customer.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                }`}>
                  {customer.status.charAt(0).toUpperCase() + customer.status.slice(1)}
                </span>
              </div>
              
              <p className="text-gray-600">{customer.category} {customer.type === 'business' ? 'Business' : 'Individual'}</p>
              
              {customer.type === 'business' && customer.contactName && (
                <p className="text-gray-600 mt-1">Primary Contact: {customer.contactName}</p>
              )}
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex px-6 -mb-px">
            <button
              onClick={() => setActiveTab('overview')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'overview'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('transactions')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'transactions'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Transactions
            </button>
            <button
              onClick={() => setActiveTab('interactions')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'interactions'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Interactions
            </button>
            <button
              onClick={() => setActiveTab('documents')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'documents'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Documents
            </button>
          </nav>
        </div>
        
        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'overview' && (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Basic Info */}
              <div className="lg:col-span-2 space-y-8">
                {/* Contact Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Contact Information</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {customer.email && (
                      <div className="flex items-start">
                        <Mail size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Email</p>
                          <a href={`mailto:${customer.email}`} className="text-[#190192] hover:underline">
                            {customer.email}
                          </a>
                        </div>
                      </div>
                    )}
                    
                    {customer.phone && (
                      <div className="flex items-start">
                        <Phone size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Phone</p>
                          <p className="text-gray-900">{customer.phone}</p>
                        </div>
                      </div>
                    )}
                    
                    {customer.website && (
                      <div className="flex items-start">
                        <Globe size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Website</p>
                          <a 
                            href={customer.website.startsWith('http') ? customer.website : `https://${customer.website}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-[#190192] hover:underline"
                          >
                            {customer.website}
                          </a>
                        </div>
                      </div>
                    )}
                    
                    {customer.address && (
                      <div className="flex items-start">
                        <MapPin size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Address</p>
                          <p className="text-gray-900">{customer.address}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Notes */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Notes</h3>
                  <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                    <p className="text-gray-700">{customer.notes || 'No notes available.'}</p>
                  </div>
                </div>
                
                {/* Recent Transactions */}
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium text-gray-900">Recent Transactions</h3>
                    <Link
                      to="/crm/sales/new"
                      className="text-sm text-[#190192] hover:text-[#2C0DB5] font-medium flex items-center"
                    >
                      <PlusCircle size={14} className="mr-1" />
                      Add Transaction
                    </Link>
                  </div>
                  
                  {customer.transactions && customer.transactions.length > 0 ? (
                    <div className="bg-white border border-gray-200 rounded-lg overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                            <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                            <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {customer.transactions.slice(0, 3).map((transaction) => (
                            <tr key={transaction.id} className="hover:bg-gray-50">
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">{transaction.date}</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-700">{transaction.description}</td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm text-right font-medium text-gray-900">${transaction.amount.toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 text-center">
                      <p className="text-gray-500">No transactions recorded yet</p>
                    </div>
                  )}
                </div>
                
                {/* Recent Interactions */}
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium text-gray-900">Recent Interactions</h3>
                    <Link
                      to={`/crm/interactions/new?customerId=${customerId}`}
                      className="text-sm text-[#190192] hover:text-[#2C0DB5] font-medium flex items-center"
                    >
                      <PlusCircle size={14} className="mr-1" />
                      Log Interaction
                    </Link>
                  </div>
                  
                  {customer.interactions && customer.interactions.length > 0 ? (
                    <div className="space-y-4">
                      {customer.interactions.slice(0, 3).map((interaction) => (
                        <div key={interaction.id} className="bg-white p-4 rounded-lg border border-gray-200 hover:shadow-sm transition-shadow">
                          <div className="flex justify-between mb-2">
                            <div className="flex items-center">
                              <div className="p-1.5 rounded bg-blue-100 text-blue-700 mr-2">
                                {interaction.type === 'email' ? <Mail size={14} /> : 
                                 interaction.type === 'meeting' ? <Users size={14} /> : 
                                 <Phone size={14} />}
                              </div>
                              <span className="text-sm font-medium text-gray-900 capitalize">{interaction.type}</span>
                            </div>
                            <span className="text-xs text-gray-500">{interaction.date}</span>
                          </div>
                          <p className="text-sm text-gray-700">{interaction.summary}</p>
                          <p className="text-xs text-gray-500 mt-2">By: {interaction.staff}</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 text-center">
                      <p className="text-gray-500">No interactions recorded yet</p>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Right Column: Sidebar */}
              <div className="lg:col-span-1">
                {/* Customer Stats */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Customer Stats</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Total Revenue</span>
                      <span className="text-sm font-medium text-gray-900">${customer.totalRevenue.toLocaleString()}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Last Contact</span>
                      <span className="text-sm font-medium text-gray-900">{customer.lastContact}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Customer Since</span>
                      <span className="text-sm font-medium text-gray-900">{customer.createdAt}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Assigned To</span>
                      <span className="text-sm font-medium text-gray-900">{customer.assignedTo || 'Unassigned'}</span>
                    </div>
                  </div>
                </div>
                
                {/* Quick Actions */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Quick Actions</h3>
                  
                  <div className="space-y-2">
                    <Link
                      to={`/crm/interactions/new?customerId=${customerId}&type=email`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <Mail size={16} className="mr-2" />
                      Send Email
                    </Link>
                    
                    <Link
                      to={`/crm/interactions/new?customerId=${customerId}&type=call`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <Phone size={16} className="mr-2" />
                      Log Call
                    </Link>
                    
                    <Link
                      to={`/crm/interactions/new?customerId=${customerId}&type=meeting`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <Calendar size={16} className="mr-2" />
                      Schedule Meeting
                    </Link>
                    
                    <Link
                      to={`/crm/sales/new?customerId=${customerId}`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <ShoppingCart size={16} className="mr-2" />
                      Create Sale
                    </Link>
                  </div>
                </div>
                
                {/* Documents */}
                <div className="bg-gray-50 rounded-xl p-5">
                  <div className="flex justify-between items-center mb-3">
                    <h3 className="text-lg font-medium text-gray-900">Documents</h3>
                    <Link
                      to={`/crm/documents/new?customerId=${customerId}`}
                      className="text-sm text-[#190192] hover:text-[#2C0DB5] font-medium flex items-center"
                    >
                      <PlusCircle size={14} className="mr-1" />
                      Add
                    </Link>
                  </div>
                  
                  {customer.documents && customer.documents.length > 0 ? (
                    <div className="space-y-2">
                      {customer.documents.map((document) => (
                        <div key={document.id} className="flex items-center p-2 border border-gray-200 rounded bg-white hover:bg-gray-50">
                          <FileText size={16} className="text-gray-400 mr-2" />
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-800 truncate">{document.name}</p>
                            <p className="text-xs text-gray-500">Uploaded: {document.uploadedAt}</p>
                          </div>
                          <button className="p-1 text-gray-400 hover:text-[#190192]">
                            <Download size={14} />
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center py-4">
                      <p className="text-gray-500 text-sm">No documents available</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          
          {activeTab === 'transactions' && (
            <div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-medium text-gray-900">Transaction History</h3>
                <Link
                  to={`/crm/sales/new?customerId=${customerId}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Add Transaction
                </Link>
              </div>
              
              {customer.transactions && customer.transactions.length > 0 ? (
                <div className="bg-white rounded-lg shadow border border-gray-200 overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {customer.transactions.map((transaction) => (
                        <tr key={transaction.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{transaction.date}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 capitalize">
                              {transaction.type}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{transaction.description}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-right font-medium text-gray-900">${transaction.amount.toLocaleString()}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-right">
                            <button className="text-[#190192] hover:text-[#2C0DB5] mr-3">
                              <Edit size={16} />
                            </button>
                            <button className="text-red-500 hover:text-red-700">
                              <Trash2 size={16} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                  <ShoppingCart size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No transactions yet</h3>
                  <p className="text-gray-500 mb-6">
                    There are no transactions recorded for this customer yet.
                  </p>
                  <Link
                    to={`/crm/sales/new?customerId=${customerId}`}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Record First Transaction
                  </Link>
                </div>
              )}
            </div>
          )}
          
          {activeTab === 'interactions' && (
            <div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-medium text-gray-900">Interaction History</h3>
                <Link
                  to={`/crm/interactions/new?customerId=${customerId}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Log Interaction
                </Link>
              </div>
              
              {customer.interactions && customer.interactions.length > 0 ? (
                <div className="space-y-4">
                  {customer.interactions.map((interaction) => (
                    <div key={interaction.id} className="bg-white p-4 rounded-lg border border-gray-200 hover:shadow-sm transition-shadow">
                      <div className="flex justify-between mb-2">
                        <div className="flex items-center">
                          <div className="p-2 rounded bg-blue-100 text-blue-700 mr-3">
                            {interaction.type === 'email' ? <Mail size={16} /> : 
                             interaction.type === 'meeting' ? <Users size={16} /> : 
                             <Phone size={16} />}
                          </div>
                          <div>
                            <span className="text-sm font-medium text-gray-900 capitalize">{interaction.type}</span>
                            <p className="text-xs text-gray-500">By: {interaction.staff}</p>
                          </div>
                        </div>
                        <span className="text-sm text-gray-500">{interaction.date}</span>
                      </div>
                      <p className="text-sm text-gray-700 mt-2">{interaction.summary}</p>
                      <div className="mt-4 flex justify-end space-x-2">
                        <button className="px-2 py-1 text-xs text-[#190192] hover:text-[#2C0DB5] flex items-center">
                          <Edit size={12} className="mr-1" /> Edit
                        </button>
                        <button className="px-2 py-1 text-xs text-red-500 hover:text-red-700 flex items-center">
                          <Trash2 size={12} className="mr-1" /> Delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                  <MessageSquare size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No interactions yet</h3>
                  <p className="text-gray-500 mb-6">
                    There are no interactions recorded for this customer yet.
                  </p>
                  <Link
                    to={`/crm/interactions/new?customerId=${customerId}`}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Log First Interaction
                  </Link>
                </div>
              )}
            </div>
          )}
          
          {activeTab === 'documents' && (
            <div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-medium text-gray-900">Documents</h3>
                <Link
                  to={`/crm/documents/new?customerId=${customerId}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Upload Document
                </Link>
              </div>
              
              {customer.documents && customer.documents.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {customer.documents.map((document) => (
                    <div key={document.id} className="bg-white p-4 rounded-lg border border-gray-200 hover:shadow-md transition-shadow">
                      <div className="flex items-center mb-3">
                        <div className="p-2 rounded bg-blue-50 text-blue-600 mr-2">
                          <FileText size={20} />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="font-medium text-gray-900 truncate">{document.name}</p>
                          <p className="text-xs text-gray-500 capitalize">{document.type}</p>
                        </div>
                      </div>
                      <div className="text-xs text-gray-500 mb-4">
                        Uploaded on {document.uploadedAt}
                      </div>
                      <div className="flex justify-end space-x-2">
                        <button className="px-2 py-1 text-xs text-blue-600 hover:text-blue-800 flex items-center">
                          <Download size={12} className="mr-1" /> Download
                        </button>
                        <button className="px-2 py-1 text-xs text-red-500 hover:text-red-700 flex items-center">
                          <Trash2 size={12} className="mr-1" /> Delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                  <FileText size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No documents yet</h3>
                  <p className="text-gray-500 mb-6">
                    There are no documents uploaded for this customer yet.
                  </p>
                  <Link
                    to={`/crm/documents/new?customerId=${customerId}`}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Upload First Document
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Customer</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete <span className="font-medium">{customer.name}</span>? This action cannot be undone and will delete all associated transactions, interactions, and documents.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerDetail;