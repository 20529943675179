// src/routes/index.js
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

// Layouts
import MainLayout from '../layouts/MainLayout';
import PublicLayout from '../layouts/PublicLayout';

// Core Pages
import Dashboard from '../pages/Dashboard';
import About from '../pages/About';
import Login from '../pages/Login';
import Register from '../pages/Register';
import CreateProfile from '../pages/CreateProfile';

import PricingPage from '../pages/subscription/PricingPage';
import SubscriptionManagement from '../pages/subscription/SubscriptionManagement';
import PaymentForm from '../pages/subscription/PaymentForm';
import SubscriptionSuccess from '../pages/subscription/SubscriptionSuccess';

import BusinessPage from '../pages/BusinessPage';
import BusinessList from '../components/business/BusinessList';
import BusinessDetail from '../components/business/BusinessDetail';
import BusinessForm from '../components/business/BusinessForm';

import PlanPage from '../pages/PlanPage';
import PlanList from '../components/plans/PlanList';
import PlanForm from '../components/plans/PlanForm';
import PlanDetail from '../components/plans/PlanDetail';

import TaskPage from '../pages/TaskPage';
import TaskList from '../components/tasks/TaskList';
import TaskForm from '../components/tasks/TaskForm';
import TaskDetail from '../components/tasks/TaskDetail';

import InventoryPage from '../pages/InventoryPage';
import StockItemList from '../components/inventory/items/StockItemList';
import StockItemDetail from '../components/inventory/items/StockItemDetail';
import StockItemForm from '../components/inventory/items/StockItemForm';
import SupplierList from '../components/inventory/suppliers/SupplierList';
import SupplierDetail from '../components/inventory/suppliers/SupplierDetail';
import SupplierForm from '../components/inventory/suppliers/SupplierForm';
import PurchaseOrderList from '../components/inventory/buying/PurchaseOrderList';
import PurchaseOrderDetail from '../components/inventory/buying/PurchaseOrderDetail';
import PurchaseOrderForm from '../components/inventory/buying/PurchaseOrderForm';
import ReceivingList from '../components/inventory/receiving/ReceivingList';
import ReceivingDetail from '../components/inventory/receiving/ReceivingDetail';
import InventoryReportList from '../components/inventory/reports/InventoryReportList';
import InventoryReportDetail from '../components/inventory/reports/InventoryReportDetail';

import RecordPage from '../pages/RecordPage';
import RecordList from '../components/records/RecordList';
import RecordDetail from '../components/records/RecordDetail';
import RecordForm from '../components/records/RecordForm';

import FinancePage from '../pages/FinancePage';
import TransactList from '../components/finance/TransactList';
import TransactForm from '../components/finance/TransactForm';
import TransactDetail from '../components/finance/TransactDetail';
import AccountsList from '../components/finance/AccountsList';
import AccountForm from '../components/finance/AccountForm';
import AccountDetail from '../components/finance/AccountDetail';

import ReportsList from '../components/finance/reports/ReportsList';
import ReportViewer from '../components/finance/reports/ReportViewer';
import ProfitLossReport from '../components/finance/reports/ProfitLossReport';
import BalanceSheetReport from '../components/finance/reports/BalanceSheetReport';
import CashFlowReport from '../components/finance/reports/CashFlowReport';
import ReceivablesAgingReport from '../components/finance/reports/ReceivablesAgingReport';
import PayablesAgingReport from '../components/finance/reports/PayablesAgingReport';
import TaxSummaryReport from '../components/finance/reports/TaxSummaryReport';
import ExpenseReport from '../components/finance/reports/ExpenseReport';

import CommunicationPage from '../pages/CommunicationPage';
import InternalMessaging from '../components/communication/InternalMessaging';
import ExternalMessaging from '../components/communication/ExternalMessaging';
import AIChat from '../components/communication/AIChat';

// Email-related component imports for routes file
import EmailTemplatesList from '../components/communication/email/EmailTemplatesList';
import EmailTemplateEditor from '../components/communication/email/EmailTemplateEditor';
import EmailSender from '../components/communication/email/EmailSender';
import EmailAnalytics from '../components/communication/email/EmailAnalytics';
import BusinessEmailSettings from '../components/communication/email/BusinessEmailSettings';
import EmailSettingsRouter from '../components/communication/email/EmailSettingsRouter';
import BusinessEmailProfileForm from '../components/communication/email/BusinessEmailProfileForm';

import Meetings from '../pages/Meetings'; // Layout for Meetings section
import MeetingList from '../components/meetings/MeetingList';
import MeetingForm from '../components/meetings/MeetingForm';
import MeetingDetails from '../components/meetings/MeetingDetails';
import AgendaForm from '../components/meetings/AgendaForm';
import MinutesCapture from '../components/meetings/MinutesCapture';

import CrmPage from '../pages/CrmPage';
import CustomerList from '../components/crm/customers/CustomerList';
import CustomerDetail from '../components/crm/customers/CustomerDetail';
import CustomerForm from '../components/crm/customers/CustomerForm';
import LeadList from '../components/crm/leads/LeadList';
import LeadForm from '../components/crm/leads/LeadForm';
import LeadDetail from '../components/crm/leads/LeadDetail';
import TicketList from '../components/crm/support/TicketList';
import TicketDetail from '../components/crm/support/TicketDetail';  
import TicketForm from '../components/crm/support/TicketForm';
import SalesPipeline from '../components/crm/sales/SalesPipeline';
import OpportunityForm from '../components/crm/sales/OpportunityForm';
import OpportunityDetail from '../components/crm/sales/OpportunityDetail';


const AppRoutes = () => {
  const { currentUser, userProfile, loading, isDemoMode } = useAuth();
  
  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="animate-spin h-12 w-12 border-4 border-indigo-600 rounded-full border-t-transparent"></div>
      </div>
    );
  }
  
  return (
    <BrowserRouter>
    <Routes>
      {/* Public routes */}
      <Route element={<PublicLayout />}>
        <Route path="/" element={<About />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={!currentUser ? <Login /> : <Navigate to="/dashboard" />} />
        <Route path="/register" element={!currentUser ? <Register /> : <Navigate to="/dashboard" />} />
        <Route path="/pricing" element={<PricingPage />} /> {/* Public pricing page */}
        <Route path="/subscription">
          <Route path="manage" element={<SubscriptionManagement />} />
          <Route path="payment" element={<PaymentForm />} />
          <Route path="upgrade" element={<PricingPage upgradeMode={true} />} />
          <Route path="success" element={<SubscriptionSuccess />} />
        </Route>
      </Route>
      
      <Route 
        path="/create-profile" 
        element={
          (currentUser || isDemoMode) && !userProfile ? 
            <CreateProfile /> : 
            ((currentUser || isDemoMode) ? <Navigate to="/dashboard" /> : <Navigate to="/login" />)
        } 
      />

<Route path="/" element={
          currentUser ? 
            (userProfile ? <MainLayout /> : <Navigate to="/create-profile" />) : 
            <Navigate to="/login" />
        }>
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="business" element={<BusinessPage />}>
          <Route index element={<BusinessList />} />
          <Route path=":id" element={<BusinessDetail />} />
          <Route path="new" element={<BusinessForm />} />
          <Route path="edit/:id" element={<BusinessForm isEditing />} />
        </Route>

        <Route path="plans" element={<PlanPage />}>
        <Route index element={<PlanList />} />
        <Route path=":id" element={<PlanDetail />} />
        <Route path="new" element={<PlanForm />} />
        <Route path="edit/:id" element={<PlanForm isEditing />} />
      </Route>

      <Route path="tasks" element={<TaskPage />}>
        <Route index element={<TaskList />} />
        <Route path=":taskId" element={<TaskDetail />} />
        <Route path="new" element={<TaskForm />} />
        <Route path="edit/:taskId" element={<TaskForm isEditing />} />
      </Route>

      {/* New Inventory routes with tabs */}
      <Route path="inventory" element={<InventoryPage />}>
        {/* Items tab */}
        <Route path="items" element={<StockItemList />} />
        <Route path="items/:itemId" element={<StockItemDetail />} />
        <Route path="items/new" element={<StockItemForm />} />
        <Route path="items/edit/:itemId" element={<StockItemForm isEditing />} />
        
        {/* Suppliers tab */}
        <Route path="suppliers" element={<SupplierList />} />
        <Route path="suppliers/:supplierId" element={<SupplierDetail />} />
        <Route path="suppliers/new" element={<SupplierForm />} />
        <Route path="suppliers/edit/:supplierId" element={<SupplierForm isEditing />} />
        
        {/* Buying/Purchase Orders tab */}
        <Route path="buying" element={<PurchaseOrderList />} />
        <Route path="buying/:poId" element={<PurchaseOrderDetail />} />
        <Route path="buying/new" element={<PurchaseOrderForm />} />
        <Route path="buying/edit/:poId" element={<PurchaseOrderForm isEditing />} />
        
        {/* Receiving tab */}
        <Route path="receiving" element={<ReceivingList />} />
        <Route path="receiving/:receivingId" element={<ReceivingDetail />} />
        
        {/* Reports tab */}
        <Route path="reports" element={<InventoryReportList />} />
        <Route path="reports/:reportId" element={<InventoryReportDetail />} />
        
        {/* Default redirect */}
        <Route index element={<Navigate to="items" replace />} />
      </Route>

      <Route path="records" element={<RecordPage />}>
        <Route index element={<RecordList />} />
        <Route path=":recordId" element={<RecordDetail />} />
        <Route path="new" element={<RecordForm />} />
        <Route path="edit/:recordId" element={<RecordForm isEditing />} />
      </Route>

      <Route path="finance" element={<FinancePage />}>
        <Route index element={<Navigate to="/finance/transactions" />} />
        
        {/* Transactions Routes */}
        <Route path="transactions" element={<TransactList />} />
        <Route path="new" element={<TransactForm />} />
        <Route path=":transactionId" element={<TransactDetail />} />
        <Route path="edit/:transactionId" element={<TransactForm isEditing />} />
        
        {/* Accounts Routes */}
        <Route path="accounts" element={<AccountsList />} />
        <Route path="accounts/new" element={<AccountForm />} />
        <Route path="accounts/:accountId" element={<AccountDetail />} />
        <Route path="accounts/edit/:accountId" element={<AccountForm isEditing />} />
        
       {/* Reports routes */}
        <Route path="reports" element={<ReportsList />} />
        <Route path="reports/profit-loss" element={<ProfitLossReport />} />
        <Route path="reports/balance-sheet" element={<BalanceSheetReport />} />
        <Route path="reports/cash-flow" element={<CashFlowReport />} />
        <Route path="reports/receivables" element={<ReceivablesAgingReport />} />
        <Route path="reports/payables" element={<PayablesAgingReport />} />
        <Route path="reports/tax" element={<TaxSummaryReport />} />
        <Route path="reports/expenses" element={<ExpenseReport />} />
        <Route path="budgets" element={<div className="bg-white p-6 rounded-xl shadow">Budgets feature coming soon</div>} />
      </Route>

      // Routes for email functionality
      <Route path="communication" element={<CommunicationPage />}>
        <Route index element={<Navigate to="/communication/internal" />} />
        <Route path="internal" element={<InternalMessaging />} />
        <Route path="external" element={<ExternalMessaging />} />
        <Route path="ai" element={<AIChat />} />
        
        {/* Email routes */}
        <Route path="email" element={<EmailTemplatesList />} />
        <Route path="email/template/new" element={<EmailTemplateEditor />} />
        <Route path="email/template/edit/:id" element={<EmailTemplateEditor />} />
        <Route path="email/send" element={<EmailSender />} />
        <Route path="email/analytics" element={<EmailAnalytics />} />
        <Route path="email/settings/:id" element={<EmailSettingsRouter />} />
        <Route path="email/profile/new" element={<BusinessEmailProfileForm />} />
      </Route>

      {/* Meetings routes */}
      <Route path="meetings" element={<Meetings />}>
            {/* Default Meetings route shows the meeting list */}
            <Route index element={<MeetingList />} />
            <Route path="create" element={<MeetingForm />} />
            <Route path="edit/:id" element={<MeetingForm isEditing />} />
            <Route path="details/:id" element={<MeetingDetails />} />
            <Route path="agenda/:id" element={<AgendaForm />} />
            <Route path="minutes/:id" element={<MinutesCapture />} />
            <Route path="*" element={<Navigate to="/meetings" replace />} />
          </Route>

          <Route path="crm" element={<CrmPage />}>
            {/* Customers */}
            <Route path="customers" element={<CustomerList />} />
            <Route path="customers/:customerId" element={<CustomerDetail />} />
            <Route path="customers/new" element={<CustomerForm />} />
            <Route path="customers/edit/:customerId" element={<CustomerForm isEditing />} />
            
            {/* Leads */}
            <Route path="leads" element={<LeadList />} />
            <Route path="leads/:leadId" element={<LeadDetail />} />
            <Route path="leads/new" element={<LeadForm />} />
            <Route path="leads/edit/:leadId" element={<LeadForm isEditing />} />
            
            {/* Sales */}
            <Route path="sales" element={<SalesPipeline />} />
            <Route path="sales/:opportunityId" element={<OpportunityDetail />} />
            <Route path="sales/new" element={<OpportunityForm />} />
            <Route path="sales/edit/:opportunityId" element={<OpportunityForm isEditing />} />
            
            {/* Communication */}
            <Route path="communication" element={<div>Customer Communication Coming Soon</div>} />
            
            {/* Support & Tickets */}
            <Route path="support" element={<TicketList />} />
            <Route path="support/:ticketId" element={<TicketDetail />} />
            <Route path="support/new" element={<TicketForm />} />
            <Route path="support/edit/:ticketId" element={<TicketForm isEditing />} />
            {/* Reports */}
            <Route path="reports" element={<div>CRM Reports Coming Soon</div>} />
            
            {/* Default redirect */}
            <Route index element={<Navigate to="customers" replace />} />
          </Route>

      </Route>
    </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;