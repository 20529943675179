// src/pages/subscription/SubscriptionSuccess.jsx
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CheckCircle, ChevronRight } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

const SubscriptionSuccess = () => {
  const { subscription } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Get the source from query params
  const params = new URLSearchParams(location.search);
  const source = params.get('source') || 'payment';
  
  // Redirect if no subscription
  useEffect(() => {
    if (!subscription) {
      navigate('/subscription/pricing');
    }
  }, [subscription, navigate]);
  
  // Example plan metadata
  const planNames = {
    basic: 'Basic',
    pro: 'Professional',
    enterprise: 'Enterprise'
  };
  
  const getPlanName = () => {
    return planNames[subscription?.planId] || 'Unknown Plan';
  };
  
  return (
    <div className="bg-white rounded-xl shadow-md max-w-2xl mx-auto p-8 text-center">
      <div className="mb-8">
        <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
          <CheckCircle className="h-10 w-10 text-green-600" />
        </div>
        <h2 className="mt-4 text-2xl font-bold text-gray-900">Subscription Successful!</h2>
        
        {source === 'trial' ? (
          <p className="mt-2 text-gray-600">
            Your 14-day free trial of the {getPlanName()} plan has started. Enjoy all features without limitations.
          </p>
        ) : (
          <p className="mt-2 text-gray-600">
            Thank you for subscribing to the {getPlanName()} plan. Your subscription is now active.
          </p>
        )}
      </div>
      
      <div className="bg-gray-50 p-5 rounded-lg mb-8">
        <h3 className="font-medium text-gray-900 mb-3">Next Steps</h3>
        <ul className="text-left space-y-3">
          <li className="flex">
            <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 mr-3" />
            <span className="text-gray-600">Explore your dashboard and start using all available features</span>
          </li>
          <li className="flex">
            <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 mr-3" />
            <span className="text-gray-600">Set up your business profile and customize your workspace</span>
          </li>
          <li className="flex">
            <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 mr-3" />
            <span className="text-gray-600">Check out our guides to make the most of BizCentral</span>
          </li>
        </ul>
      </div>
      
      <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4 justify-center">
        <button
          onClick={() => navigate('/dashboard')}
          className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-[#190192] hover:bg-[#2C0DB5]"
        >
          Go to Dashboard
          <ChevronRight className="ml-2 h-5 w-5" />
        </button>
        
        <button
          onClick={() => navigate('/subscription/manage')}
          className="inline-flex items-center px-5 py-2 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Manage Subscription
        </button>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;