// src/pages/subscription/PaymentForm.jsx (modified for PayFast integration)
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Check, AlertCircle, CheckCircle, Clock, ExternalLink } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import PayfastLogo from '../../assets/images/payfast-logo.png'; // Add this image to your assets

// PayFast service functions (to be created in separate file)
const initiatePayfastPayment = async (paymentData) => {
  // This would be implemented in a proper service file
  // Simple implementation for now
  
  // Generate PayFast URL
  const payfastBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://www.payfast.co.za/eng/process'
    : 'https://sandbox.payfast.co.za/eng/process';
    
  // These would come from your env variables in production
  const merchantId = 'MERCHANT_ID';
  const merchantKey = 'MERCHANT_KEY';
  
  const params = new URLSearchParams({
    merchant_id: merchantId,
    merchant_key: merchantKey,
    return_url: `${window.location.origin}/subscription/success`,
    cancel_url: `${window.location.origin}/subscription/cancelled`,
    notify_url: `${process.env.REACT_APP_API_URL || 'http://localhost:5001'}/api/subscription/webhook`,
    name_first: paymentData.firstName || 'Customer',
    name_last: paymentData.lastName || '',
    email_address: paymentData.email || '',
    m_payment_id: `sub_${Date.now()}`, // Generate unique ID
    amount: paymentData.amount,
    item_name: `${paymentData.planName} Subscription`,
    subscription_type: paymentData.billingInterval === 'monthly' ? '1' : '3', // 1 for monthly, 3 for annual
    billing_date: new Date().getDate(),
    recurring_amount: paymentData.amount,
    frequency: paymentData.billingInterval === 'monthly' ? '3' : '6', // 3 for monthly, 6 for annual
    cycles: paymentData.billingInterval === 'monthly' ? '0' : '1', // 0 for indefinite, 1 for annual
  });
  
  // Add custom fields for discounts
  if (paymentData.discountType === 'launch') {
    params.append('custom_str1', 'launch_discount');
    params.append('custom_str2', '50_percent');
  }
  
  // In production, you'd add signature here
  
  return `${payfastBaseUrl}?${params.toString()}`;
};

const PaymentForm = () => {
  const { currentUser, userProfile } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get('plan');
  const initialInterval = queryParams.get('interval') || 'monthly';
  const initialCurrency = queryParams.get('currency') || 'ZAR';
  const discountType = queryParams.get('discount') || 'none';
  
  const [billingInterval, setBillingInterval] = useState(initialInterval);
  const [currency, setCurrency] = useState(initialCurrency);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  
  // Plan pricing in ZAR and USD
  const planPricing = {
    ZAR: {
      basic: 360,
      pro: 600,
      enterprise: 900
    },
    USD: {
      basic: 19.99,
      pro: 34.99,
      enterprise: 54.99
    }
  };
  
  // Plan names
  const planNames = {
    basic: 'Basic',
    pro: 'Professional',
    enterprise: 'Enterprise'
  };
  
  // Format price according to selected currency
  const formatPrice = (price) => {
    if (currency === 'ZAR') {
      return new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
        currencyDisplay: 'symbol'
      }).format(price);
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol'
      }).format(price);
    }
  };
  
  // Fetch plan details
  useEffect(() => {
    if (!planId) {
      navigate('/subscription/pricing');
      return;
    }
    
    // Define plan features
    const planFeatures = {
      basic: { 
        name: 'Basic', 
        features: [
          'Up to 3 businesses',
          'Basic inventory management',
          'Customer records'
        ]
      },
      pro: { 
        name: 'Professional', 
        features: [
          'Up to 10 businesses',
          'Advanced inventory management',
          'Email campaigns'
        ] 
      },
      enterprise: { 
        name: 'Enterprise', 
        features: [
          'Unlimited businesses',
          'Advanced analytics',
          'API access'
        ] 
      }
    };
    
    setPlanDetails({
      id: planId,
      ...planFeatures[planId]
    });
  }, [planId, navigate]);
  
  // Calculate annual price (10 months instead of 12)
  const getAnnualPrice = (monthlyPrice) => {
    return (monthlyPrice * 10).toFixed(2);
  };
  
  // Calculate discounted price if applicable
  const getDiscountedPrice = (originalPrice) => {
    if (discountType === 'launch') {
      return originalPrice * 0.5; // 50% off
    }
    return originalPrice;
  };
  
  // Get the current price based on all factors
  const getCurrentPrice = () => {
    if (!planDetails) return '0.00';
    
    const basePrice = planPricing[currency][planDetails.id];
    const price = billingInterval === 'monthly' 
      ? basePrice
      : getAnnualPrice(basePrice);
    
    return getDiscountedPrice(price);
  };
  
  // Get original price (without discount)
  const getOriginalPrice = () => {
    if (!planDetails) return '0.00';
    
    const basePrice = planPricing[currency][planDetails.id];
    return billingInterval === 'monthly' 
      ? basePrice
      : getAnnualPrice(basePrice);
  };
  
  const handleBillingToggle = (interval) => {
    setBillingInterval(interval);
  };
  
  const handlePayWithPayfast = async () => {
    if (!currentUser) {
      navigate('/login');
      return;
    }
    
    setIsProcessing(true);
    setError(null);
    
    try {
      // Prepare payment data for PayFast
      const paymentData = {
        planId: planId,
        planName: planNames[planId] || 'Subscription',
        amount: getCurrentPrice(),
        currency: currency, 
        billingInterval: billingInterval,
        discountType: discountType,
        // User info
        email: currentUser.email,
        firstName: userProfile?.firstName || '',
        lastName: userProfile?.lastName || ''
      };
      
      // Get PayFast payment URL
      const payfastUrl = await initiatePayfastPayment(paymentData);
      
      // Save subscription info to database before redirecting
      // This would use your existing subscribe function
      // await subscribe(planId, billingInterval, currency, null, discountType);
      
      // Redirect to PayFast
      window.location.href = payfastUrl;
    } catch (error) {
      console.error("Error initiating payment:", error);
      setError("Failed to initiate payment. Please try again.");
      setIsProcessing(false);
    }
  };
  
  if (!planDetails) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }
  
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden max-w-3xl mx-auto">
      <div className="px-6 py-4 bg-gray-50 border-b">
        <h2 className="text-xl font-medium text-gray-900">Complete Your Purchase</h2>
      </div>
      
      <div className="p-6">
        {/* Launch Discount Banner */}
        {discountType === 'launch' && (
          <div className="mb-6 bg-gradient-to-r from-yellow-400 to-orange-500 rounded-lg p-4 text-white">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <CheckCircle className="h-5 w-5 text-white" />
              </div>
              <div className="ml-3">
                <h3 className="text-white font-bold">Launch Discount Applied!</h3>
                <p className="text-sm">You're getting 50% off for your first year!</p>
              </div>
            </div>
          </div>
        )}
      
        {/* Plan Summary */}
        <div className="mb-8 bg-gray-50 rounded-lg p-4 border">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Order Summary</h3>
          <div className="flex justify-between items-center">
            <div>
              <p className="text-gray-700">{planDetails.name} Plan</p>
              <p className="text-sm text-gray-500">
                Billed {billingInterval === 'monthly' ? 'monthly' : 'annually'}
              </p>
            </div>
            <div className="text-right">
              <p className="text-lg font-medium text-gray-900">
                {formatPrice(getCurrentPrice())}
                <span className="text-sm text-gray-500">
                  /{billingInterval === 'monthly' ? 'month' : 'year'}
                </span>
              </p>
              
              {/* Show original price if discounted */}
              {discountType === 'launch' && (
                <p className="text-sm text-gray-500 line-through">
                  {formatPrice(getOriginalPrice())}
                  /{billingInterval === 'monthly' ? 'month' : 'year'}
                </p>
              )}
            </div>
          </div>
          
          {/* If discounted, show discount details */}
          {discountType === 'launch' && (
            <div className="mt-2 pt-2 border-t border-gray-200">
              <div className="flex justify-between text-sm">
                <span className="font-medium text-green-600">Launch Discount (50%)</span>
                <span className="font-medium text-green-600">
                  -{formatPrice(getOriginalPrice() - getCurrentPrice())}
                </span>
              </div>
              <p className="text-xs text-gray-500 mt-1">
                <Clock className="inline-block h-3 w-3 mr-1" />
                This discount applies to your first year of subscription
              </p>
            </div>
          )}
          
          {/* Billing Interval Selector */}
          <div className="mt-4 pt-4 border-t border-gray-200">
            <div className="flex justify-between items-center">
              <p className="text-sm font-medium text-gray-700">Billing Interval</p>
              <div className="flex space-x-3">
                <button
                  type="button"
                  onClick={() => handleBillingToggle('monthly')}
                  className={`px-3 py-1 text-sm rounded-md ${
                    billingInterval === 'monthly'
                      ? 'bg-[#190192] text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  Monthly
                </button>
                <button
                  type="button"
                  onClick={() => handleBillingToggle('annual')}
                  className={`px-3 py-1 text-sm rounded-md ${
                    billingInterval === 'annual'
                      ? 'bg-[#190192] text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  Annual
                </button>
              </div>
            </div>
            
            {billingInterval === 'annual' && (
              <div className="mt-2 flex items-center text-sm text-green-600">
                <CheckCircle className="h-4 w-4 mr-1" />
                Save 17% with annual billing (2 months free)
              </div>
            )}
          </div>
          
          {/* Currency Selector */}
          <div className="mt-4 pt-4 border-t border-gray-200">
            <div className="flex justify-between items-center">
              <p className="text-sm font-medium text-gray-700">Currency</p>
              <select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-[#190192] focus:ring-[#190192] sm:text-sm"
              >
                <option value="ZAR">ZAR (R)</option>
                <option value="USD">USD ($)</option>
              </select>
            </div>
          </div>
        </div>
      
        {error && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertCircle className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}
        
        {/* PayFast Payment Section */}
        <div className="bg-gray-50 rounded-lg p-6 border border-gray-200 mb-6">
          <div className="flex justify-center mb-4">
            <img 
              src={PayfastLogo} 
              alt="PayFast" 
              className="h-10"
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = 'none';
              }}
            />
          </div>
          
          <p className="text-center text-gray-600 mb-4">
            You'll be redirected to PayFast to complete your payment securely.
            PayFast accepts credit cards, instant EFT, and other payment methods.
          </p>
          
          <div className="flex flex-wrap justify-center gap-2 mb-4">
            <img src="https://www.payfast.co.za/assets/images/logos/cards/visa.svg" alt="Visa" className="h-8" />
            <img src="https://www.payfast.co.za/assets/images/logos/cards/mastercard.svg" alt="Mastercard" className="h-8" />
            <img src="https://www.payfast.co.za/assets/images/logos/eft/instanteft.svg" alt="Instant EFT" className="h-8" />
            <img src="https://www.payfast.co.za/assets/images/logos/wallets/mobicred.svg" alt="Mobicred" className="h-8" />
          </div>
        </div>
        
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate('/subscription/pricing')}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
            disabled={isProcessing}
          >
            Back
          </button>
          
          <button
            type="button"
            onClick={handlePayWithPayfast}
            className="px-6 py-2.5 bg-[#190192] text-white rounded-md hover:bg-[#2C0DB5] disabled:bg-gray-400 flex items-center"
            disabled={isProcessing}
          >
            {isProcessing ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing...
              </>
            ) : (
              <>
                Pay with PayFast <ExternalLink size={16} className="ml-2" />
              </>
            )}
          </button>
        </div>
        
        <div className="mt-6 text-center text-xs text-gray-500">
          <p>
            By proceeding with payment, you agree to our 
            <a href="/terms" className="text-[#190192] hover:underline">Terms of Service</a> and 
            <a href="/privacy" className="text-[#190192] hover:underline">Privacy Policy</a>.
          </p>
          <p className="mt-1">
            All payments are processed securely through PayFast, South Africa's trusted payment provider.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;