// src/components/crm/leads/LeadDetail.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Download, Trash2, User, 
  Mail, Phone, Calendar, DollarSign, Briefcase,
  Tag, MapPin, Globe, FileText, PlusCircle, ChevronRight,
  MessageSquare, X, CheckCircle, Star, Users
} from 'lucide-react';

const LeadDetail = () => {
  const { leadId } = useParams();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [lead, setLead] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Fetch lead details
  useEffect(() => {
    const fetchLeadDetails = async () => {
      setLoading(true);
      try {
        // In a real app, this would be an API call
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock lead data
        const mockLead = {
          id: leadId,
          name: 'John Doe',
          company: 'Alpha Technologies',
          email: 'john@alphatech.com',
          phone: '+1 (555) 123-4567',
          stage: 'qualified',
          source: 'website',
          assignedTo: 'Sarah Kim',
          createdAt: '2025-03-15',
          lastContact: '2025-03-15',
          estimatedValue: 5000,
          address: '123 Tech Street, San Francisco, CA 94107',
          website: 'www.alphatech.com',
          notes: 'Interested in our enterprise solution. Has budget approval for Q2.',
          tags: ['Enterprise', 'High Value', 'Software'],
          activities: [
            { id: '1', type: 'email', date: '2025-03-15', summary: 'Sent product information', user: 'Sarah Kim' },
            { id: '2', type: 'call', date: '2025-03-12', summary: 'Discussed requirements and budget', user: 'Sarah Kim' },
            { id: '3', type: 'meeting', date: '2025-03-08', summary: 'Initial discovery call', user: 'Michael Johnson' }
          ],
          tasks: [
            { id: '1', title: 'Send proposal', dueDate: '2025-03-20', status: 'pending', assignedTo: 'Sarah Kim' },
            { id: '2', title: 'Schedule demo', dueDate: '2025-03-25', status: 'pending', assignedTo: 'David Chen' }
          ],
          documents: [
            { id: '1', name: 'Requirements.pdf', uploadedAt: '2025-03-10', type: 'document' },
            { id: '2', name: 'Initial Meeting Notes.docx', uploadedAt: '2025-03-08', type: 'document' }
          ]
        };
        
        setLead(mockLead);
      } catch (error) {
        console.error('Error fetching lead details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeadDetails();
  }, [leadId]);

  // Handle delete
  const handleDelete = async () => {
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Navigate back to the leads list
      navigate('/crm/leads');
    } catch (error) {
      console.error('Error deleting lead:', error);
    }
  };

  // Handle stage change
  const handleStageChange = (newStage) => {
    setLead(prev => ({
      ...prev,
      stage: newStage
    }));
  };

  // Format stage for display
  const formatStage = (stage) => {
    switch (stage) {
      case 'new':
        return { label: 'New', color: 'bg-blue-100 text-blue-800', icon: Star };
      case 'qualified':
        return { label: 'Qualified', color: 'bg-purple-100 text-purple-800', icon: CheckCircle };
      case 'proposal':
        return { label: 'Proposal', color: 'bg-amber-100 text-amber-800', icon: FileText };
      case 'negotiation':
        return { label: 'Negotiation', color: 'bg-orange-100 text-orange-800', icon: Briefcase };
      case 'closed_won':
        return { label: 'Closed Won', color: 'bg-green-100 text-green-800', icon: CheckCircle };
      case 'closed_lost':
        return { label: 'Closed Lost', color: 'bg-red-100 text-red-800', icon: X };
      default:
        return { label: stage, color: 'bg-gray-100 text-gray-800', icon: Star };
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!lead) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Lead not found</h3>
          <p className="text-gray-500 mb-6">The lead you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/crm/leads')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Leads
          </button>
        </div>
      </div>
    );
  }

  // Get stage info
  const stageInfo = formatStage(lead.stage);
  const StageIcon = stageInfo.icon;

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/crm/leads"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Leads
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/crm/leads/edit/${leadId}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Lead Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0 text-blue-800">
              <User size={32} />
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{lead.name}</h1>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${stageInfo.color}`}>
                  <StageIcon size={12} className="mr-1" />
                  {stageInfo.label}
                </span>
              </div>
              
              <p className="text-gray-600">{lead.company}</p>
              <p className="text-gray-500 mt-1">Source: {lead.source}</p>
            </div>
            
            <div className="sm:text-right">
              <p className="text-xl font-bold text-[#190192]">${lead.estimatedValue.toLocaleString()}</p>
              <p className="text-sm text-gray-500">Estimated Value</p>
            </div>
          </div>
        </div>
        
        {/* Lead Stage Progression */}
        <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row items-center">
            <p className="text-sm font-medium text-gray-700 mr-4 mb-2 sm:mb-0">Lead Stage:</p>
            <div className="flex flex-wrap gap-2">
              <button
                onClick={() => handleStageChange('new')}
                className={`px-3 py-1.5 rounded-lg border text-sm flex items-center ${
                  lead.stage === 'new' 
                    ? 'bg-blue-100 border-blue-400 text-blue-800' 
                    : 'border-gray-300 text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Star size={14} className="mr-1.5" /> New
              </button>
              <button
                onClick={() => handleStageChange('qualified')}
                className={`px-3 py-1.5 rounded-lg border text-sm flex items-center ${
                  lead.stage === 'qualified' 
                    ? 'bg-purple-100 border-purple-400 text-purple-800' 
                    : 'border-gray-300 text-gray-600 hover:bg-gray-50'
                }`}
              >
                <CheckCircle size={14} className="mr-1.5" /> Qualified
              </button>
              <button
                onClick={() => handleStageChange('proposal')}
                className={`px-3 py-1.5 rounded-lg border text-sm flex items-center ${
                  lead.stage === 'proposal' 
                    ? 'bg-amber-100 border-amber-400 text-amber-800' 
                    : 'border-gray-300 text-gray-600 hover:bg-gray-50'
                }`}
              >
                <FileText size={14} className="mr-1.5" /> Proposal
              </button>
              <button
                onClick={() => handleStageChange('negotiation')}
                className={`px-3 py-1.5 rounded-lg border text-sm flex items-center ${
                  lead.stage === 'negotiation' 
                    ? 'bg-orange-100 border-orange-400 text-orange-800' 
                    : 'border-gray-300 text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Briefcase size={14} className="mr-1.5" /> Negotiation
              </button>
              <button
                onClick={() => handleStageChange('closed_won')}
                className={`px-3 py-1.5 rounded-lg border text-sm flex items-center ${
                  lead.stage === 'closed_won' 
                    ? 'bg-green-100 border-green-400 text-green-800' 
                    : 'border-gray-300 text-gray-600 hover:bg-gray-50'
                }`}
              >
                <CheckCircle size={14} className="mr-1.5" /> Closed Won
              </button>
              <button
                onClick={() => handleStageChange('closed_lost')}
                className={`px-3 py-1.5 rounded-lg border text-sm flex items-center ${
                  lead.stage === 'closed_lost' 
                    ? 'bg-red-100 border-red-400 text-red-800' 
                    : 'border-gray-300 text-gray-600 hover:bg-gray-50'
                }`}
              >
                <X size={14} className="mr-1.5" /> Closed Lost
              </button>
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex px-6 -mb-px">
            <button
              onClick={() => setActiveTab('overview')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'overview'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('activities')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'activities'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Activities
            </button>
            <button
              onClick={() => setActiveTab('tasks')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'tasks'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Tasks
            </button>
            <button
              onClick={() => setActiveTab('documents')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'documents'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Documents
            </button>
          </nav>
        </div>
        
        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'overview' && (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Basic Info */}
              <div className="lg:col-span-2 space-y-8">
                {/* Contact Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Contact Information</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {lead.email && (
                      <div className="flex items-start">
                        <Mail size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Email</p>
                          <a href={`mailto:${lead.email}`} className="text-[#190192] hover:underline">
                            {lead.email}
                          </a>
                        </div>
                      </div>
                    )}
                    
                    {lead.phone && (
                      <div className="flex items-start">
                        <Phone size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Phone</p>
                          <p className="text-gray-900">{lead.phone}</p>
                        </div>
                      </div>
                    )}
                    
                    {lead.website && (
                      <div className="flex items-start">
                        <Globe size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Website</p>
                          <a 
                            href={lead.website.startsWith('http') ? lead.website : `https://${lead.website}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-[#190192] hover:underline"
                          >
                            {lead.website}
                          </a>
                        </div>
                      </div>
                    )}
                    
                    {lead.address && (
                      <div className="flex items-start">
                        <MapPin size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Address</p>
                          <p className="text-gray-900">{lead.address}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Notes */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Notes</h3>
                  <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                    <p className="text-gray-700">{lead.notes || 'No notes available.'}</p>
                  </div>
                </div>
                
                {/* Tags */}
                {lead.tags && lead.tags.length > 0 && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Tags</h3>
                    <div className="flex flex-wrap gap-2">
                      {lead.tags.map((tag, index) => (
                        <span key={index} className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                
                {/* Recent Activities */}
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium text-gray-900">Recent Activities</h3>
                    <Link
                      to={`/crm/leads/activities/new?leadId=${leadId}`}
                      className="text-sm text-[#190192] hover:text-[#2C0DB5] font-medium flex items-center"
                    >
                      <PlusCircle size={14} className="mr-1" />
                      Log Activity
                    </Link>
                  </div>
                  
                  {lead.activities && lead.activities.length > 0 ? (
                    <div className="space-y-4">
                      {lead.activities.slice(0, 3).map((activity) => (
                        <div key={activity.id} className="bg-white p-4 rounded-lg border border-gray-200 hover:shadow-sm transition-shadow">
                          <div className="flex justify-between mb-2">
                            <div className="flex items-center">
                              <div className="p-1.5 rounded bg-blue-100 text-blue-700 mr-2">
                                {activity.type === 'email' ? <Mail size={14} /> : 
                                 activity.type === 'meeting' ? <Users size={14} /> : 
                                 <Phone size={14} />}
                              </div>
                              <span className="text-sm font-medium text-gray-900 capitalize">{activity.type}</span>
                            </div>
                            <span className="text-xs text-gray-500">{activity.date}</span>
                          </div>
                          <p className="text-sm text-gray-700">{activity.summary}</p>
                          <p className="text-xs text-gray-500 mt-2">By: {activity.user}</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 text-center">
                      <p className="text-gray-500">No activities recorded yet</p>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Right Column: Sidebar */}
              <div className="lg:col-span-1">
                {/* Lead Stats */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Lead Information</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Estimated Value</span>
                      <span className="text-sm font-medium text-gray-900">${lead.estimatedValue.toLocaleString()}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Last Contact</span>
                      <span className="text-sm font-medium text-gray-900">{lead.lastContact}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Created On</span>
                      <span className="text-sm font-medium text-gray-900">{lead.createdAt}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Assigned To</span>
                      <span className="text-sm font-medium text-gray-900">{lead.assignedTo || 'Unassigned'}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Source</span>
                      <span className="text-sm font-medium text-gray-900 capitalize">{lead.source}</span>
                    </div>
                  </div>
                </div>
                
                {/* Quick Actions */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Quick Actions</h3>
                  
                  <div className="space-y-2">
                    <Link
                      to={`/crm/leads/activities/new?leadId=${leadId}&type=email`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <Mail size={16} className="mr-2" />
                      Send Email
                    </Link>
                    
                    <Link
                      to={`/crm/leads/activities/new?leadId=${leadId}&type=call`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <Phone size={16} className="mr-2" />
                      Log Call
                    </Link>
                    
                    <Link
                      to={`/crm/leads/activities/new?leadId=${leadId}&type=meeting`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <Calendar size={16} className="mr-2" />
                      Schedule Meeting
                    </Link>
                    
                    <Link
                      to={`/crm/tasks/new?leadId=${leadId}`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <CheckCircle size={16} className="mr-2" />
                      Create Task
                    </Link>
                    
                    {lead.stage !== 'closed_won' && (
                      <Link
                        to={`/crm/customers/new?convertFromLead=${leadId}`}
                        className="block w-full text-center px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center justify-center"
                      >
                        <User size={16} className="mr-2" />
                        Convert to Customer
                      </Link>
                    )}
                  </div>
                </div>
                
                {/* Tasks */}
                <div className="bg-gray-50 rounded-xl p-5">
                  <div className="flex justify-between items-center mb-3">
                    <h3 className="text-lg font-medium text-gray-900">Upcoming Tasks</h3>
                    <Link
                      to={`/crm/tasks/new?leadId=${leadId}`}
                      className="text-sm text-[#190192] hover:text-[#2C0DB5] font-medium flex items-center"
                    >
                      <PlusCircle size={14} className="mr-1" />
                      Add
                    </Link>
                  </div>
                  
                  {lead.tasks && lead.tasks.length > 0 ? (
                    <div className="space-y-2">
                      {lead.tasks.map((task) => (
                        <div key={task.id} className="flex items-center p-2 border border-gray-200 rounded bg-white hover:bg-gray-50">
                          <input type="checkbox" className="h-4 w-4 text-[#190192] rounded border-gray-300 mr-3" />
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-800 truncate">{task.title}</p>
                            <p className="text-xs text-gray-500">Due: {task.dueDate} • {task.assignedTo}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center py-4">
                      <p className="text-gray-500 text-sm">No upcoming tasks</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          
          {activeTab === 'activities' && (
            <div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-medium text-gray-900">Activity History</h3>
                <Link
                  to={`/crm/leads/activities/new?leadId=${leadId}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Log Activity
                </Link>
              </div>
              
              {lead.activities && lead.activities.length > 0 ? (
                <div className="space-y-4">
                  {lead.activities.map((activity) => (
                    <div key={activity.id} className="bg-white p-4 rounded-lg border border-gray-200 hover:shadow-sm transition-shadow">
                      <div className="flex justify-between mb-2">
                        <div className="flex items-center">
                          <div className="p-2 rounded bg-blue-100 text-blue-700 mr-3">
                            {activity.type === 'email' ? <Mail size={16} /> : 
                             activity.type === 'meeting' ? <Users size={16} /> : 
                             <Phone size={16} />}
                          </div>
                          <div>
                            <span className="text-sm font-medium text-gray-900 capitalize">{activity.type}</span>
                            <p className="text-xs text-gray-500">By: {activity.user}</p>
                          </div>
                        </div>
                        <span className="text-sm text-gray-500">{activity.date}</span>
                      </div>
                      <p className="text-sm text-gray-700 mt-2">{activity.summary}</p>
                      <div className="mt-4 flex justify-end space-x-2">
                        <button className="px-2 py-1 text-xs text-[#190192] hover:text-[#2C0DB5] flex items-center">
                          <Edit size={12} className="mr-1" /> Edit
                        </button>
                        <button className="px-2 py-1 text-xs text-red-500 hover:text-red-700 flex items-center">
                          <Trash2 size={12} className="mr-1" /> Delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                  <MessageSquare size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No activities yet</h3>
                  <p className="text-gray-500 mb-6">
                    There are no activities recorded for this lead yet.
                  </p>
                  <Link
                    to={`/crm/leads/activities/new?leadId=${leadId}`}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Log First Activity
                  </Link>
                </div>
              )}
            </div>
          )}
          
          {activeTab === 'tasks' && (
            <div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-medium text-gray-900">Tasks</h3>
                <Link
                  to={`/crm/tasks/new?leadId=${leadId}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Add Task
                </Link>
              </div>
              
              {lead.tasks && lead.tasks.length > 0 ? (
                <div className="bg-white rounded-lg shadow border border-gray-200 overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Task</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Due Date</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Assigned To</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {lead.tasks.map((task) => (
                        <tr key={task.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <input type="checkbox" className="h-4 w-4 text-[#190192] rounded border-gray-300 mr-3" />
                              <span className="text-sm font-medium text-gray-900">{task.title}</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{task.dueDate}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{task.assignedTo}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800 capitalize">
                              {task.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button className="text-[#190192] hover:text-[#2C0DB5] mr-3">
                              <Edit size={16} />
                            </button>
                            <button className="text-red-500 hover:text-red-700">
                              <Trash2 size={16} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                  <CheckCircle size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No tasks yet</h3>
                  <p className="text-gray-500 mb-6">
                    There are no tasks created for this lead yet.
                  </p>
                  <Link
                    to={`/crm/tasks/new?leadId=${leadId}`}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Create First Task
                  </Link>
                </div>
              )}
            </div>
          )}
          
          {activeTab === 'documents' && (
            <div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-medium text-gray-900">Documents</h3>
                <Link
                  to={`/crm/documents/new?leadId=${leadId}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Upload Document
                </Link>
              </div>
              
              {lead.documents && lead.documents.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {lead.documents.map((document) => (
                    <div key={document.id} className="bg-white p-4 rounded-lg border border-gray-200 hover:shadow-md transition-shadow">
                      <div className="flex items-center mb-3">
                        <div className="p-2 rounded bg-blue-50 text-blue-600 mr-2">
                          <FileText size={20} />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="font-medium text-gray-900 truncate">{document.name}</p>
                          <p className="text-xs text-gray-500 capitalize">{document.type}</p>
                        </div>
                      </div>
                      <div className="text-xs text-gray-500 mb-4">
                        Uploaded on {document.uploadedAt}
                      </div>
                      <div className="flex justify-end space-x-2">
                        <button className="px-2 py-1 text-xs text-blue-600 hover:text-blue-800 flex items-center">
                          <Download size={12} className="mr-1" /> Download
                        </button>
                        <button className="px-2 py-1 text-xs text-red-500 hover:text-red-700 flex items-center">
                          <Trash2 size={12} className="mr-1" /> Delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                  <FileText size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No documents yet</h3>
                  <p className="text-gray-500 mb-6">
                    There are no documents uploaded for this lead yet.
                  </p>
                  <Link
                    to={`/crm/documents/new?leadId=${leadId}`}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Upload First Document
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Lead</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete <span className="font-medium">{lead.name}</span>? This action cannot be undone and will delete all associated activities, tasks, and documents.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadDetail;