// src/components/crm/leads/LeadList.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, User, 
  Mail, Phone, Calendar, DollarSign, Briefcase, X
} from 'lucide-react';

const LeadList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStage, setFilterStage] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [leads, setLeads] = useState([]);

  // Fetch leads
  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(r => setTimeout(r, 800));
        
        // Mock lead data
        const mockData = [
          {
            id: '1',
            name: 'John Doe',
            company: 'Alpha Technologies',
            email: 'john@alphatech.com',
            phone: '+1 (555) 123-4567',
            stage: 'new',
            source: 'website',
            assignedTo: 'Sarah Kim',
            createdAt: '2025-03-15',
            lastContact: '2025-03-15',
            estimatedValue: 5000
          },
          {
            id: '2',
            name: 'Jane Smith',
            company: 'Beta Solutions',
            email: 'jane@betasolutions.com',
            phone: '+1 (555) 987-6543',
            stage: 'qualified',
            source: 'referral',
            assignedTo: 'Michael Johnson',
            createdAt: '2025-03-10',
            lastContact: '2025-03-14',
            estimatedValue: 7500
          },
          {
            id: '3',
            name: 'Robert Chen',
            company: 'Gamma Corp',
            email: 'robert@gammacorp.com',
            phone: '+1 (555) 456-7890',
            stage: 'proposal',
            source: 'cold call',
            assignedTo: 'David Chen',
            createdAt: '2025-03-05',
            lastContact: '2025-03-12',
            estimatedValue: 12000
          },
          {
            id: '4',
            name: 'Emily Davis',
            company: 'Delta Industries',
            email: 'emily@deltaindustries.com',
            phone: '+1 (555) 789-0123',
            stage: 'negotiation',
            source: 'event',
            assignedTo: 'Sarah Kim',
            createdAt: '2025-02-28',
            lastContact: '2025-03-10',
            estimatedValue: 20000
          },
          {
            id: '5',
            name: 'Michael Wilson',
            company: 'Epsilon Marketing',
            email: 'michael@epsilonmarketing.com',
            phone: '+1 (555) 234-5678',
            stage: 'closed_won',
            source: 'advertisement',
            assignedTo: 'Michael Johnson',
            createdAt: '2025-02-20',
            lastContact: '2025-03-08',
            estimatedValue: 9500
          }
        ];
        
        setLeads(mockData);
      } catch (error) {
        console.error('Error fetching leads:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchLeads();
  }, []);

  // Get filtered leads
  const getFilteredLeads = () => {
    let filtered = leads;
    
    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(lead => 
        lead.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Filter by stage
    if (filterStage !== 'all') {
      filtered = filtered.filter(lead => lead.stage === filterStage);
    }
    
    return filtered;
  };

  // Format stage for display
  const formatStage = (stage) => {
    switch (stage) {
      case 'new':
        return { label: 'New', color: 'bg-blue-100 text-blue-800' };
      case 'qualified':
        return { label: 'Qualified', color: 'bg-purple-100 text-purple-800' };
      case 'proposal':
        return { label: 'Proposal', color: 'bg-amber-100 text-amber-800' };
      case 'negotiation':
        return { label: 'Negotiation', color: 'bg-orange-100 text-orange-800' };
      case 'closed_won':
        return { label: 'Closed Won', color: 'bg-green-100 text-green-800' };
      case 'closed_lost':
        return { label: 'Closed Lost', color: 'bg-red-100 text-red-800' };
      default:
        return { label: stage, color: 'bg-gray-100 text-gray-800' };
    }
  };

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search leads by name, company, or email..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterStage}
            onChange={(e) => setFilterStage(e.target.value)}
          >
            <option value="all">All Stages</option>
            <option value="new">New</option>
            <option value="qualified">Qualified</option>
            <option value="proposal">Proposal</option>
            <option value="negotiation">Negotiation</option>
            <option value="closed_won">Closed Won</option>
            <option value="closed_lost">Closed Lost</option>
          </select>
          
          <Link
            to="/crm/leads/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
          >
            <PlusCircle size={18} className="mr-2" />
            Add Lead
          </Link>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {leads.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Briefcase size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No leads found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't added any leads yet. Click the 'Add Lead' button to get started.
              </p>
              <Link
                to="/crm/leads/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                <PlusCircle size={16} className="mr-2" />
                Add Your First Lead
              </Link>
            </div>
          ) : getFilteredLeads().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Briefcase size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching leads</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any leads matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterStage('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Leads List/Table */
            <div className="bg-white rounded-xl shadow-md overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Lead
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Contact
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Stage
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Assigned To
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Last Contact
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Value
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {getFilteredLeads().map((lead) => {
                      const stageInfo = formatStage(lead.stage);
                      return (
                        <tr key={lead.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-800">
                                <User size={20} />
                              </div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">{lead.name}</div>
                                <div className="text-sm text-gray-500">{lead.company}</div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900 flex items-center">
                              <Mail size={14} className="mr-1 text-gray-400" /> {lead.email}
                            </div>
                            {lead.phone && (
                              <div className="text-sm text-gray-500 flex items-center">
                                <Phone size={14} className="mr-1 text-gray-400" /> {lead.phone}
                              </div>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${stageInfo.color}`}>
                              {stageInfo.label}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {lead.assignedTo}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex items-center">
                            <Calendar size={14} className="mr-1 text-gray-400" /> {lead.lastContact}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right font-medium">
                            ${lead.estimatedValue.toLocaleString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <Link
                              to={`/crm/leads/${lead.id}`}
                              className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                            >
                              View <ChevronRight size={16} className="ml-1" />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LeadList;