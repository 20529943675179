// src/components/crm/sales/OpportunityForm.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  ChevronLeft, Save, AlertCircle, Building, User,
  Mail, Phone, DollarSign, Calendar, Star, 
  CheckCircle, FileText, Briefcase, X, Tag, 
  CreditCard, Users, Percent
} from 'lucide-react';

const OpportunityForm = ({ isEditing = false }) => {
  const { opportunityId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialStage = queryParams.get('stage') || 'lead';
  
  const [loading, setLoading] = useState(isEditing);
  const [saving, setSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  
  const [formData, setFormData] = useState({
    title: '',
    company: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    stage: initialStage,
    amount: '',
    closeDate: '',
    probability: '',
    assignedTo: '',
    description: '',
    nextSteps: '',
    notes: ''
  });
  
  // Staff members (for assignment)
  const [staffMembers, setStaffMembers] = useState([
    { id: '1', name: 'Michael Johnson' },
    { id: '2', name: 'Sarah Kim' },
    { id: '3', name: 'David Chen' }
  ]);
  
  // Stage options with visual info
  const stageOptions = [
    { id: 'lead', label: 'Lead', icon: Star, color: 'blue' },
    { id: 'qualified', label: 'Qualified', icon: CheckCircle, color: 'purple' },
    { id: 'proposal', label: 'Proposal', icon: FileText, color: 'amber' },
    { id: 'negotiation', label: 'Negotiation', icon: Briefcase, color: 'orange' },
    { id: 'closed_won', label: 'Closed Won', icon: CheckCircle, color: 'green' },
    { id: 'closed_lost', label: 'Closed Lost', icon: X, color: 'red' }
  ];

  // Calculate default probability based on stage
  const getDefaultProbability = (stage) => {
    switch(stage) {
      case 'lead': return '20';
      case 'qualified': return '40';
      case 'proposal': return '60';
      case 'negotiation': return '80';
      case 'closed_won': return '100';
      case 'closed_lost': return '0';
      default: return '20';
    }
  };
  
  // Update probability when stage changes
  useEffect(() => {
    if (!isEditing || formData.probability === '') {
      setFormData(prev => ({
        ...prev,
        probability: getDefaultProbability(formData.stage)
      }));
    }
  }, [formData.stage, isEditing]);
  
  // Set tomorrow's date as the default close date for new opportunities
  useEffect(() => {
    if (!isEditing && !formData.closeDate) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 30); // Default to 30 days from now
      
      const formattedDate = tomorrow.toISOString().split('T')[0]; // YYYY-MM-DD
      
      setFormData(prev => ({
        ...prev,
        closeDate: formattedDate
      }));
    }
  }, [isEditing, formData.closeDate]);
  
  // Fetch opportunity data if editing
  useEffect(() => {
    if (isEditing && opportunityId) {
      const fetchOpportunityData = async () => {
        setLoading(true);
        try {
          // In a real app, this would be an API call
          await new Promise(resolve => setTimeout(resolve, 800));
          
          // Mock opportunity data
          const mockOpportunity = {
            id: opportunityId,
            title: 'Enterprise Software Upgrade',
            company: 'Acme Corporation',
            contactName: 'John Smith',
            contactEmail: 'john@acmecorp.com',
            contactPhone: '+1 (555) 123-4567',
            stage: 'proposal',
            amount: '45000',
            closeDate: '2025-05-15',
            probability: '60',
            assignedTo: '2', // Sarah Kim's ID
            description: 'Upgrading their existing software suite to the latest version with additional modules for HR and finance.',
            nextSteps: 'Schedule a demo with the technical team and prepare a detailed ROI analysis.',
            notes: 'Client is interested in our premium package but has budget constraints. We need to demonstrate ROI clearly.'
          };
          
          setFormData(mockOpportunity);
        } catch (error) {
          console.error('Error fetching opportunity:', error);
        } finally {
          setLoading(false);
        }
      };
      
      fetchOpportunityData();
    }
  }, [isEditing, opportunityId]);
  
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle checkboxes
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Handle stage selection
  const handleStageChange = (stage) => {
    setFormData(prev => ({
      ...prev,
      stage
    }));
    
    if (formErrors.stage) {
      setFormErrors(prev => ({
        ...prev,
        stage: ''
      }));
    }
  };
  
  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!formData.title.trim()) {
      errors.title = 'Opportunity title is required';
    }
    
    if (!formData.company.trim()) {
      errors.company = 'Company name is required';
    }
    
    if (!formData.contactName.trim()) {
      errors.contactName = 'Contact name is required';
    }
    
    if (!formData.contactEmail.trim()) {
      errors.contactEmail = 'Contact email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.contactEmail)) {
      errors.contactEmail = 'Email is invalid';
    }
    
    if (!formData.stage) {
      errors.stage = 'Stage is required';
    }
    
    if (!formData.closeDate) {
      errors.closeDate = 'Close date is required';
    }
    
    if (!formData.amount.trim()) {
      errors.amount = 'Amount is required';
    } else if (isNaN(parseFloat(formData.amount))) {
      errors.amount = 'Amount must be a number';
    }
    
    if (!formData.probability.trim()) {
      errors.probability = 'Probability is required';
    } else if (isNaN(parseInt(formData.probability)) || parseInt(formData.probability) < 0 || parseInt(formData.probability) > 100) {
      errors.probability = 'Probability must be a number between 0 and 100';
    }
    
    return errors;
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      
      // Scroll to first error
      const firstErrorElement = document.querySelector('.text-red-600');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      return;
    }
    
    setSaving(true);
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Navigate back to the opportunities list or detail page
      navigate(isEditing ? `/crm/sales/${opportunityId}` : '/crm/sales');
    } catch (error) {
      console.error('Error saving opportunity:', error);
      setFormErrors({ submit: 'An error occurred while saving. Please try again.' });
    } finally {
      setSaving(false);
    }
  };
  
  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }
  
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex items-center">
          <button 
            type="button"
            onClick={() => navigate('/crm/sales')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center mr-4"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back
          </button>
          <h2 className="text-lg font-medium text-gray-900">
            {isEditing ? 'Edit Opportunity' : 'Add New Opportunity'}
          </h2>
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        {/* Form Error Message */}
        {formErrors.submit && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
            <div className="flex">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <p>{formErrors.submit}</p>
            </div>
          </div>
        )}
        
        {/* Opportunity Stage */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Opportunity Stage <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-wrap gap-2">
            {stageOptions.map((stage) => {
              const StageIcon = stage.icon;
              return (
                <button
                  key={stage.id}
                  type="button"
                  onClick={() => handleStageChange(stage.id)}
                  className={`px-3 py-2 rounded-lg border text-sm flex items-center ${
                    formData.stage === stage.id 
                      ? `bg-${stage.color}-100 border-${stage.color}-400 text-${stage.color}-800` 
                      : 'border-gray-300 text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <StageIcon size={16} className="mr-1.5" /> {stage.label}
                </button>
              );
            })}
          </div>
          {formErrors.stage && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.stage}
            </p>
          )}
        </div>
        
        {/* Basic Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Opportunity Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Opportunity Title */}
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
              Opportunity Name <span className="text-red-500">*</span>
            </label>
            <input
              id="title"
              name="title"
              type="text"
              className={`block w-full rounded-lg border ${
                formErrors.title ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              value={formData.title}
              onChange={handleInputChange}
              placeholder="e.g. Enterprise Software Upgrade"
            />
            {formErrors.title && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.title}
              </p>
            )}
          </div>
          
          {/* Company */}
          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">
              Company <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Building size={18} className="text-gray-400" />
              </div>
              <input
                id="company"
                name="company"
                type="text"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.company ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.company}
                onChange={handleInputChange}
                placeholder="Acme Corporation"
              />
            </div>
            {formErrors.company && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.company}
              </p>
            )}
          </div>
        </div>
        
        {/* Contact Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Contact Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          {/* Contact Name */}
          <div>
            <label htmlFor="contactName" className="block text-sm font-medium text-gray-700 mb-1">
              Contact Name <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <User size={18} className="text-gray-400" />
              </div>
              <input
                id="contactName"
                name="contactName"
                type="text"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.contactName ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.contactName}
                onChange={handleInputChange}
                placeholder="John Doe"
              />
            </div>
            {formErrors.contactName && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.contactName}
              </p>
            )}
          </div>
          
          {/* Contact Email */}
          <div>
            <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700 mb-1">
              Contact Email <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail size={18} className="text-gray-400" />
              </div>
              <input
                id="contactEmail"
                name="contactEmail"
                type="email"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.contactEmail ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.contactEmail}
                onChange={handleInputChange}
                placeholder="john@example.com"
              />
            </div>
            {formErrors.contactEmail && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.contactEmail}
              </p>
            )}
          </div>
          
          {/* Contact Phone */}
          <div>
            <label htmlFor="contactPhone" className="block text-sm font-medium text-gray-700 mb-1">
              Contact Phone
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Phone size={18} className="text-gray-400" />
              </div>
              <input
                id="contactPhone"
                name="contactPhone"
                type="text"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.contactPhone}
                onChange={handleInputChange}
                placeholder="+1 (555) 123-4567"
              />
            </div>
          </div>
        </div>
        
        {/* Deal Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Deal Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
          {/* Amount */}
          <div>
            <label htmlFor="amount" className="block text-sm font-medium text-gray-700 mb-1">
              Amount <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign size={18} className="text-gray-400" />
              </div>
              <input
                id="amount"
                name="amount"
                type="text"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.amount ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.amount}
                onChange={handleInputChange}
                placeholder="0.00"
              />
            </div>
            {formErrors.amount && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.amount}
              </p>
            )}
          </div>
          
          {/* Probability */}
          <div>
            <label htmlFor="probability" className="block text-sm font-medium text-gray-700 mb-1">
              Probability (%) <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Percent size={18} className="text-gray-400" />
              </div>
              <input
                id="probability"
                name="probability"
                type="text"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.probability ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.probability}
                onChange={handleInputChange}
                placeholder="50"
              />
            </div>
            {formErrors.probability && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.probability}
              </p>
            )}
          </div>
          
          {/* Close Date */}
          <div>
            <label htmlFor="closeDate" className="block text-sm font-medium text-gray-700 mb-1">
              Expected Close Date <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar size={18} className="text-gray-400" />
              </div>
              <input
                id="closeDate"
                name="closeDate"
                type="date"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.closeDate ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.closeDate}
                onChange={handleInputChange}
              />
            </div>
            {formErrors.closeDate && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.closeDate}
              </p>
            )}
          </div>
          
          {/* Assigned To */}
          <div>
            <label htmlFor="assignedTo" className="block text-sm font-medium text-gray-700 mb-1">
              Assigned To
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Users size={18} className="text-gray-400" />
              </div>
              <select
                id="assignedTo"
                name="assignedTo"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.assignedTo}
                onChange={handleInputChange}
              >
                <option value="">Unassigned</option>
                {staffMembers.map((staff) => (
                  <option key={staff.id} value={staff.id}>{staff.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        
        {/* Description */}
        <div className="mb-6">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            rows="3"
            className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Describe the opportunity in detail..."
          />
        </div>
        
        {/* Next Steps */}
        <div className="mb-6">
          <label htmlFor="nextSteps" className="block text-sm font-medium text-gray-700 mb-1">
            Next Steps
          </label>
          <textarea
            id="nextSteps"
            name="nextSteps"
            rows="2"
            className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
            value={formData.nextSteps}
            onChange={handleInputChange}
            placeholder="What are the next actions to move this opportunity forward?"
          />
        </div>
        
        {/* Notes */}
        <div className="mb-6">
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
            Notes
          </label>
          <textarea
            id="notes"
            name="notes"
            rows="3"
            className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
            value={formData.notes}
            onChange={handleInputChange}
            placeholder="Add any additional notes about this opportunity..."
          />
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={() => navigate('/crm/sales')}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            disabled={saving}
          >
            Cancel
          </button>
          
          <button
            type="submit"
            disabled={saving}
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              saving ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
          >
            {saving ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <Save size={18} className="mr-2" />
                {isEditing ? 'Update Opportunity' : 'Create Opportunity'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default OpportunityForm;