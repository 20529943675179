// src/pages/subscription/PricingPage.jsx
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CheckCircle, Globe, Settings } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

const PricingPage = () => {
  const { currentUser, subscription, isTrialing, startFreeTrial } = useAuth();
  const [billingInterval, setBillingInterval] = useState('monthly');
  const [currency, setCurrency] = useState('ZAR'); // Default currency is ZAR
  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if we're in upgrade mode
  const isUpgradeMode = location.pathname.includes('/subscription/upgrade');
  
  // Plan pricing in ZAR and USD
  const planPricing = {
    ZAR: {
      basic: 360,
      pro: 600,
      enterprise: 900
    },
    USD: {
      basic: 19.99,
      pro: 34.99,
      enterprise: 54.99
    }
  };
  
  // Launch discount (50% off)
  const [showLaunchDiscount, setShowLaunchDiscount] = useState(true);
  const remainingDiscountSpots = 100; // This would be fetched from your database
  
  // Calculate annual discount (2 months free = 10 months cost)
  const getAnnualPrice = (monthlyPrice) => {
    return (monthlyPrice * 10).toFixed(2);
  };
  
  // Format price according to selected currency
  const formatPrice = (price) => {
    if (currency === 'ZAR') {
      return new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
        currencyDisplay: 'symbol'
      }).format(price);
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol'
      }).format(price);
    }
  };
  
  // Apply 50% launch discount if applicable
  const getDiscountedPrice = (originalPrice) => {
    if (showLaunchDiscount) {
      return originalPrice * 0.5;
    }
    return originalPrice;
  };
  
  const plans = [
    {
      id: 'basic',
      name: 'Basic',
      features: [
        'Up to 3 businesses',
        'Basic inventory management',
        'Customer records',
        'Email templates',
        '5GB storage'
      ],
      isPopular: false
    },
    {
      id: 'pro',
      name: 'Professional',
      features: [
        'Up to 10 businesses',
        'Advanced inventory management',
        'Customer analytics',
        'Email campaigns',
        'Direct messaging',
        '20GB storage'
      ],
      isPopular: true
    },
    {
      id: 'enterprise',
      name: 'Enterprise',
      features: [
        'Unlimited businesses',
        'Complete inventory & order system',
        'Advanced analytics',
        'Email & SMS campaigns',
        'API access',
        '100GB storage'
      ],
      isPopular: false
    }
  ];
  
  const handleToggleBilling = () => {
    setBillingInterval(billingInterval === 'monthly' ? 'annual' : 'monthly');
  };
  
  const handleSelectPlan = async (planId, skipTrial = false) => {
    if (!currentUser) {
      // Redirect to login with return URL to pricing
      navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
      return;
    }
    
    const isDiscounted = showLaunchDiscount && remainingDiscountSpots > 0;
    
    if (isUpgradeMode || skipTrial) {
      // Go directly to payment with plan and interval info
      navigate(`/subscription/payment?plan=${planId}&interval=${billingInterval}&currency=${currency}&discount=${isDiscounted ? 'launch' : 'none'}`);
    } else {
      // Start a free trial
      try {
        await startFreeTrial(planId, billingInterval, currency, isDiscounted ? 'launch' : null);
        navigate('/dashboard');
      } catch (error) {
        console.error("Error starting trial:", error);
      }
    }
  };
  
  return (
    <div className="py-12 pb-0 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Simple, transparent pricing
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Start with a 14-day free trial. No credit card required.
          </p>
        </div>
        
        {/* Launch Discount Banner */}
        {showLaunchDiscount && (
          <div className="mt-8 bg-gradient-to-r from-yellow-400 to-orange-500 rounded-lg p-4 text-center shadow-md">
            <h3 className="text-lg font-bold text-white">🚀 Launch Special: 50% OFF!</h3>
            <p className="text-white mt-1">
              First 100 subscribers get 50% off for the first year. 
              <span className="font-bold"> Only {remainingDiscountSpots} spots left!</span>
            </p>
          </div>
        )}
        
        {/* Currency Selector */}
        <div className="mt-6 flex justify-end">
          <div className="flex items-center space-x-2">
            <Globe size={16} className="text-gray-500" />
            <span className="text-sm text-gray-600">Currency:</span>
            <select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              className="block rounded-md border-gray-300 shadow-sm focus:border-[#190192] focus:ring-[#190192] sm:text-sm"
            >
              <option value="ZAR">ZAR (R)</option>
              <option value="USD">USD ($)</option>
            </select>
          </div>
        </div>
        
        {/* Billing Interval Toggle */}
        <div className="mt-4 flex justify-center">
          <div className="relative flex items-center p-1 bg-gray-100 rounded-lg">
            <button
              onClick={handleToggleBilling}
              className={`px-4 py-2 ${
                billingInterval === 'monthly' ? 'bg-white shadow-sm rounded-md text-[#190192]' : 'text-gray-500'
              } text-sm font-medium transition-all`}
            >
              Monthly
            </button>
            
            <button
              onClick={handleToggleBilling}
              className={`px-4 py-2 ml-0.5 ${
                billingInterval === 'annual' ? 'bg-white shadow-sm rounded-md text-[#190192]' : 'text-gray-500'
              } text-sm font-medium transition-all relative`}
            >
              Annual
              <span className="absolute -top-2 -right-2 bg-green-100 text-green-800 text-xs px-1.5 py-0.5 rounded-full">
                Save 17%
              </span>
            </button>
          </div>
        </div>

        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 lg:max-w-5xl lg:mx-auto xl:max-w-none xl:mx-0">
          {plans.map((plan) => {
            // Get base price in selected currency
            const basePrice = planPricing[currency][plan.id];
            
            // Apply discounts
            const monthlyPrice = getDiscountedPrice(basePrice);
            const annualPrice = getDiscountedPrice(getAnnualPrice(basePrice));
            
            // Get formatted prices
            const formattedMonthly = formatPrice(monthlyPrice);
            const formattedAnnual = formatPrice(annualPrice);
            
            return (
              <div 
                key={plan.id} 
                className={`bg-white rounded-lg shadow-md divide-y divide-gray-200 ${
                  plan.isPopular ? 'border-2 border-[#190192] transform scale-105' : ''
                }`}
              >
                {plan.isPopular && (
                  <div className="bg-[#190192] text-white text-center py-1 px-4 rounded-t-lg">
                    Most Popular
                  </div>
                )}
                
                <div className="p-6">
                  <h3 className="text-2xl font-medium text-gray-900">{plan.name}</h3>
                  <p className="mt-4">
                    <span className="text-4xl font-extrabold text-gray-900">
                      {billingInterval === 'monthly' ? formattedMonthly : formattedAnnual}
                    </span>
                    <span className="text-base font-medium text-gray-500">
                      /{billingInterval === 'monthly' ? 'month' : 'year'}
                    </span>
                  </p>
                  
                  {/* Show original price if discounted */}
                  {showLaunchDiscount && (
                    <p className="mt-1 text-sm text-gray-500 line-through">
                      Normal: {formatPrice(billingInterval === 'monthly' ? basePrice : getAnnualPrice(basePrice))}
                    </p>
                  )}
                  
                  {billingInterval === 'annual' && (
                    <p className="mt-1 text-sm text-green-600 font-medium">
                      2 months free
                    </p>
                  )}
                  
                  <div className="mt-8 space-y-3">
                    <button
                      onClick={() => handleSelectPlan(plan.id, false)}
                      className={`w-full rounded-md py-2 px-4 text-sm font-medium text-white ${
                        plan.isPopular ? 'bg-[#190192] hover:bg-[#2C0DB5]' : 'bg-gray-800 hover:bg-gray-700'
                      }`}
                    >
                      {isUpgradeMode ? 'Select Plan' : 'Start 14-Day Trial'}
                    </button>
                    
                    {!isUpgradeMode && (
                      <button
                        onClick={() => handleSelectPlan(plan.id, true)}
                        className="w-full rounded-md py-2 px-4 text-sm font-medium text-gray-700 border border-gray-300 hover:bg-gray-50"
                      >
                        Subscribe Now
                      </button>
                    )}
                  </div>
                </div>
                
                <div className="pt-6 pb-8 px-6">
                  <h4 className="text-sm font-medium text-gray-900 tracking-wide">
                    What's included
                  </h4>
                  <ul className="mt-4 space-y-3">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="flex">
                        <CheckCircle className="flex-shrink-0 h-5 w-5 text-green-500" />
                        <span className="ml-3 text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Footer Section */}
            <footer className="bg-gray-900 text-white py-12 mt-16">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                  <div>
                    <h3 className="text-sm font-semibold uppercase tracking-wider">Product</h3>
                    <ul className="mt-4 space-y-2">
                      <li><a href="#features" className="text-gray-300 hover:text-white">Features</a></li>
                      <li><a href="#pricing" className="text-gray-300 hover:text-white">Pricing</a></li>
                      <li><a href="#testimonials" className="text-gray-300 hover:text-white">Testimonials</a></li>
                      <li><a href="#faq" className="text-gray-300 hover:text-white">FAQ</a></li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="text-sm font-semibold uppercase tracking-wider">Support</h3>
                    <ul className="mt-4 space-y-2">
                      <li><a href="#help" className="text-gray-300 hover:text-white">Help Center</a></li>
                      <li><a href="#contact" className="text-gray-300 hover:text-white">Contact Us</a></li>
                      <li><a href="#docs" className="text-gray-300 hover:text-white">Documentation</a></li>
                      <li><a href="#status" className="text-gray-300 hover:text-white">System Status</a></li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="text-sm font-semibold uppercase tracking-wider">Company</h3>
                    <ul className="mt-4 space-y-2">
                      <li><a href="#about" className="text-gray-300 hover:text-white">About Us</a></li>
                      <li><a href="#careers" className="text-gray-300 hover:text-white">Careers</a></li>
                      <li><a href="#blog" className="text-gray-300 hover:text-white">Blog</a></li>
                      <li><a href="#press" className="text-gray-300 hover:text-white">Press</a></li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="text-sm font-semibold uppercase tracking-wider">Legal</h3>
                    <ul className="mt-4 space-y-2">
                      <li><a href="#privacy" className="text-gray-300 hover:text-white">Privacy Policy</a></li>
                      <li><a href="#terms" className="text-gray-300 hover:text-white">Terms of Service</a></li>
                      <li><a href="#security" className="text-gray-300 hover:text-white">Security</a></li>
                      <li><a href="#compliance" className="text-gray-300 hover:text-white">Compliance</a></li>
                    </ul>
                  </div>
                </div>
                <div className="mt-12 border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between items-center">
                  <div className="flex items-center">
                    <div className="w-10 h-10 rounded-xl bg-[#190192] flex items-center justify-center">
                      <Settings size={20} className="text-white" />
                    </div>
                    <p className="ml-3 text-xl font-bold">Bizcentral</p>
                  </div>
                  <p className="mt-4 md:mt-0 text-gray-400 text-sm">
                    &copy; 2025 Bizcentral, Inc. All rights reserved.
                  </p>
                </div>
              </div>
            </footer>
    </div>
  );
};

export default PricingPage;