// src/components/crm/leads/LeadForm.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  ChevronLeft, Save, AlertCircle, User, Building,
  Mail, Phone, Globe, MapPin, Tag, Users, 
  DollarSign, Star, CheckCircle, FileText, Briefcase
} from 'lucide-react';

const LeadForm = ({ isEditing = false }) => {
  const { leadId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  const [loading, setLoading] = useState(isEditing);
  const [saving, setSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    stage: 'new',
    source: '',
    website: '',
    address: '',
    notes: '',
    estimatedValue: '',
    assignedTo: '',
    tags: []
  });
  
  // Staff members (for assignment)
  const [staffMembers, setStaffMembers] = useState([
    { id: '1', name: 'Michael Johnson' },
    { id: '2', name: 'Sarah Kim' },
    { id: '3', name: 'David Chen' }
  ]);
  
  // Source options
  const sourceOptions = [
    'website', 'referral', 'cold call', 'event', 'advertisement', 'social media', 'email', 'other'
  ];
  
  // Stage options with visual info
  const stageOptions = [
    { id: 'new', label: 'New', icon: Star, color: 'blue' },
    { id: 'qualified', label: 'Qualified', icon: CheckCircle, color: 'purple' },
    { id: 'proposal', label: 'Proposal', icon: FileText, color: 'amber' },
    { id: 'negotiation', label: 'Negotiation', icon: Briefcase, color: 'orange' },
    { id: 'closed_won', label: 'Closed Won', icon: CheckCircle, color: 'green' },
    { id: 'closed_lost', label: 'Closed Lost', icon: AlertCircle, color: 'red' }
  ];
  
  // Tag input state
  const [tagInput, setTagInput] = useState('');
  
  // Fetch lead data if editing
  useEffect(() => {
    if (isEditing && leadId) {
      const fetchLeadData = async () => {
        setLoading(true);
        try {
          // In a real app, this would be an API call
          await new Promise(resolve => setTimeout(resolve, 800));
          
          // Mock lead data
          const mockLead = {
            id: leadId,
            name: 'John Doe',
            company: 'Alpha Technologies',
            email: 'john@alphatech.com',
            phone: '+1 (555) 123-4567',
            stage: 'qualified',
            source: 'website',
            assignedTo: '2', // Sarah Kim's ID
            website: 'www.alphatech.com',
            address: '123 Tech Street, San Francisco, CA 94107',
            notes: 'Interested in our enterprise solution. Has budget approval for Q2.',
            estimatedValue: '5000',
            tags: ['Enterprise', 'High Value', 'Software']
          };
          
          setFormData(mockLead);
        } catch (error) {
          console.error('Error fetching lead:', error);
        } finally {
          setLoading(false);
        }
      };
      
      fetchLeadData();
    }
  }, [isEditing, leadId]);
  
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle checkboxes
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Handle stage selection
  const handleStageChange = (stage) => {
    setFormData(prev => ({
      ...prev,
      stage
    }));
    
    if (formErrors.stage) {
      setFormErrors(prev => ({
        ...prev,
        stage: ''
      }));
    }
  };
  
  // Handle tag input
  const handleTagKeyDown = (e) => {
    if (e.key === 'Enter' && tagInput.trim()) {
      e.preventDefault();
      
      // Add tag if it doesn't already exist
      if (!formData.tags.includes(tagInput.trim())) {
        setFormData(prev => ({
          ...prev,
          tags: [...prev.tags, tagInput.trim()]
        }));
      }
      
      setTagInput('');
    }
  };
  
  // Remove tag
  const handleRemoveTag = (tagToRemove) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };
  
  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!formData.name.trim()) {
      errors.name = 'Lead name is required';
    }
    
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    
    if (!formData.company.trim()) {
      errors.company = 'Company name is required';
    }
    
    if (!formData.stage) {
      errors.stage = 'Stage is required';
    }
    
    if (!formData.source) {
      errors.source = 'Source is required';
    }
    
    if (formData.estimatedValue && isNaN(parseFloat(formData.estimatedValue))) {
      errors.estimatedValue = 'Value must be a number';
    }
    
    return errors;
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      
      // Scroll to first error
      const firstErrorElement = document.querySelector('.text-red-600');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      return;
    }
    
    setSaving(true);
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Navigate back to the lead list or detail page
      navigate(isEditing ? `/crm/leads/${leadId}` : '/crm/leads');
    } catch (error) {
      console.error('Error saving lead:', error);
      setFormErrors({ submit: 'An error occurred while saving. Please try again.' });
    } finally {
      setSaving(false);
    }
  };
  
  // Get stage color
  const getStageColor = (stageId) => {
    const stage = stageOptions.find(s => s.id === stageId);
    if (!stage) return 'gray';
    return stage.color;
  };
  
  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }
  
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex items-center">
          <button 
            type="button"
            onClick={() => navigate('/crm/leads')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center mr-4"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back
          </button>
          <h2 className="text-lg font-medium text-gray-900">
            {isEditing ? 'Edit Lead' : 'Add New Lead'}
          </h2>
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        {/* Form Error Message */}
        {formErrors.submit && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
            <div className="flex">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <p>{formErrors.submit}</p>
            </div>
          </div>
        )}
        
        {/* Basic Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Lead Name */}
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Contact Name <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <User size={18} className="text-gray-400" />
              </div>
              <input
                id="name"
                name="name"
                type="text"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.name ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.name}
                onChange={handleInputChange}
                placeholder="John Doe"
              />
            </div>
            {formErrors.name && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.name}
              </p>
            )}
          </div>
          
          {/* Company */}
          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">
              Company <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Building size={18} className="text-gray-400" />
              </div>
              <input
                id="company"
                name="company"
                type="text"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.company ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.company}
                onChange={handleInputChange}
                placeholder="Acme Corporation"
              />
            </div>
            {formErrors.company && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.company}
              </p>
            )}
          </div>
        </div>
        
        {/* Contact Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Contact Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Email */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail size={18} className="text-gray-400" />
              </div>
              <input
                id="email"
                name="email"
                type="email"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.email ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.email}
                onChange={handleInputChange}
                placeholder="email@example.com"
              />
            </div>
            {formErrors.email && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.email}
              </p>
            )}
          </div>
          
          {/* Phone */}
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
              Phone
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Phone size={18} className="text-gray-400" />
              </div>
              <input
                id="phone"
                name="phone"
                type="text"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="+1 (555) 123-4567"
              />
            </div>
          </div>
          
          {/* Website */}
          <div>
            <label htmlFor="website" className="block text-sm font-medium text-gray-700 mb-1">
              Website
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Globe size={18} className="text-gray-400" />
              </div>
              <input
                id="website"
                name="website"
                type="text"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.website}
                onChange={handleInputChange}
                placeholder="www.example.com"
              />
            </div>
          </div>
          
          {/* Address */}
          <div>
            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
              Address
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MapPin size={18} className="text-gray-400" />
              </div>
              <input
                id="address"
                name="address"
                type="text"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="123 Main St, City, State, ZIP"
              />
            </div>
          </div>
        </div>
        
        {/* Lead Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Lead Information</h3>
        
        {/* Lead Stage */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Stage <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-wrap gap-2">
            {stageOptions.map((stage) => {
              const StageIcon = stage.icon;
              return (
                <button
                  key={stage.id}
                  type="button"
                  onClick={() => handleStageChange(stage.id)}
                  className={`px-3 py-2 rounded-lg border text-sm flex items-center ${
                    formData.stage === stage.id 
                      ? `bg-${stage.color}-100 border-${stage.color}-400 text-${stage.color}-800` 
                      : 'border-gray-300 text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <StageIcon size={16} className="mr-1.5" /> {stage.label}
                </button>
              );
            })}
          </div>
          {formErrors.stage && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.stage}
            </p>
          )}
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Source */}
          <div>
            <label htmlFor="source" className="block text-sm font-medium text-gray-700 mb-1">
              Source <span className="text-red-500">*</span>
            </label>
            <select
              id="source"
              name="source"
              className={`block w-full rounded-lg border ${
                formErrors.source ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              value={formData.source}
              onChange={handleInputChange}
            >
              <option value="">Select Source</option>
              {sourceOptions.map((source) => (
                <option key={source} value={source}>
                  {source.charAt(0).toUpperCase() + source.slice(1)}
                </option>
              ))}
            </select>
            {formErrors.source && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.source}
              </p>
            )}
          </div>
          
          {/* Estimated Value */}
          <div>
            <label htmlFor="estimatedValue" className="block text-sm font-medium text-gray-700 mb-1">
              Estimated Value
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign size={18} className="text-gray-400" />
              </div>
              <input
                id="estimatedValue"
                name="estimatedValue"
                type="text"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.estimatedValue ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.estimatedValue}
                onChange={handleInputChange}
                placeholder="5000"
              />
            </div>
            {formErrors.estimatedValue && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.estimatedValue}
              </p>
            )}
          </div>
          
          {/* Assigned To */}
          <div>
            <label htmlFor="assignedTo" className="block text-sm font-medium text-gray-700 mb-1">
              Assigned To
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Users size={18} className="text-gray-400" />
              </div>
              <select
                id="assignedTo"
                name="assignedTo"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.assignedTo}
                onChange={handleInputChange}
              >
                <option value="">Unassigned</option>
                {staffMembers.map((staff) => (
                  <option key={staff.id} value={staff.id}>{staff.name}</option>
                ))}
              </select>
            </div>
          </div>
          
          {/* Tags */}
          <div>
            <label htmlFor="tags" className="block text-sm font-medium text-gray-700 mb-1">
              Tags
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Tag size={18} className="text-gray-400" />
              </div>
              <input
                id="tags"
                type="text"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                onKeyDown={handleTagKeyDown}
                placeholder="Add tag and press Enter"
              />
            </div>
            {formData.tags.length > 0 && (
              <div className="mt-2 flex flex-wrap gap-2">
                {formData.tags.map((tag) => (
                  <span key={tag} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                    {tag}
                    <button
                      type="button"
                      onClick={() => handleRemoveTag(tag)}
                      className="ml-1.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-gray-200"
                    >
                      <span className="sr-only">Remove tag</span>
                      <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                      </svg>
                    </button>
                  </span>
                ))}
              </div>
            )}
            <p className="mt-1 text-xs text-gray-500">
              Press Enter after each tag
            </p>
          </div>
        </div>
        
        {/* Notes */}
        <div className="mb-6">
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
            Notes
          </label>
          <textarea
            id="notes"
            name="notes"
            rows="4"
            className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
            value={formData.notes}
            onChange={handleInputChange}
            placeholder="Add any additional notes about this lead..."
          />
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={() => navigate('/crm/leads')}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            disabled={saving}
          >
            Cancel
          </button>
          
          <button
            type="submit"
            disabled={saving}
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              saving ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
          >
            {saving ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <Save size={18} className="mr-2" />
                {isEditing ? 'Update Lead' : 'Create Lead'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LeadForm;