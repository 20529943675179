// src/components/crm/support/TicketForm.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  ChevronLeft, Save, AlertCircle, Building, User,
  Mail, Phone, Tag, Calendar, MessageSquare, 
  Clock, Check, Trash2, Plus, Upload, X
} from 'lucide-react';

const TicketForm = ({ isEditing = false }) => {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [loading, setLoading] = useState(isEditing);
  const [saving, setSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  
  const [formData, setFormData] = useState({
    subject: '',
    customerName: '',
    customerEmail: '',
    customerPhone: '',
    company: '',
    category: '',
    priority: 'medium',
    status: 'new',
    assignedTo: '',
    dueDate: '',
    description: '',
    attachments: []
  });
  
  // Categories
  const categoryOptions = [
    'Account Access', 'Technical Support', 'Billing', 'Feature Request', 
    'Product Issue', 'General Inquiry'
  ];
  
  // Status options
  const statusOptions = [
    { id: 'new', label: 'New', color: 'blue' },
    { id: 'in_progress', label: 'In Progress', color: 'yellow' },
    { id: 'waiting', label: 'Waiting for Customer', color: 'purple' },
    { id: 'resolved', label: 'Resolved', color: 'green' },
    { id: 'closed', label: 'Closed', color: 'gray' }
  ];
  
  // Priority options
  const priorityOptions = [
    { id: 'low', label: 'Low', color: 'green' },
    { id: 'medium', label: 'Medium', color: 'orange' },
    { id: 'high', label: 'High', color: 'red' }
  ];
  
  // Staff members (for assignment)
  const [staffMembers, setStaffMembers] = useState([
    { id: '1', name: 'Michael Johnson' },
    { id: '2', name: 'Sarah Kim' },
    { id: '3', name: 'David Chen' }
  ]);

  // Set today as the default due date for new tickets
  useEffect(() => {
    if (!isEditing && !formData.dueDate) {
      const twoDaysLater = new Date();
      twoDaysLater.setDate(twoDaysLater.getDate() + 2); // Default to 2 days from now
      
      const formattedDate = twoDaysLater.toISOString().split('T')[0]; // YYYY-MM-DD
      
      setFormData(prev => ({
        ...prev,
        dueDate: formattedDate
      }));
    }
  }, [isEditing, formData.dueDate]);
  
  // Fetch ticket data if editing
  useEffect(() => {
    if (isEditing && ticketId) {
      const fetchTicketData = async () => {
        setLoading(true);
        try {
          // In a real app, this would be an API call
          await new Promise(resolve => setTimeout(resolve, 800));
          
          // Mock ticket data
          const mockTicket = {
            id: ticketId,
            subject: 'Cannot access account after password reset',
            customerName: 'John Smith',
            customerEmail: 'john@example.com',
            customerPhone: '+1 (555) 123-4567',
            company: 'Acme Corporation',
            category: 'Account Access',
            priority: 'high',
            status: 'in_progress',
            assignedTo: '2', // Sarah Kim's ID
            dueDate: '2025-03-23',
            description: "Customer reports being unable to log in after password reset. They've tried multiple times with the same result. This is affecting their ability to access important account features.",
            attachments: []
          };
          
          setFormData(mockTicket);
        } catch (error) {
          console.error('Error fetching ticket:', error);
        } finally {
          setLoading(false);
        }
      };
      
      fetchTicketData();
    }
  }, [isEditing, ticketId]);
  
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle checkboxes
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Handle file upload
  const handleFileUpload = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    
    const newAttachments = Array.from(files).map(file => ({
      id: Date.now().toString() + Math.random().toString(36).substring(2),
      name: file.name,
      type: file.type,
      size: `${Math.round(file.size / 1024)}KB`
    }));
    
    setFormData(prev => ({
      ...prev,
      attachments: [...prev.attachments, ...newAttachments]
    }));
    
    // Reset input
    e.target.value = '';
  };
  
  // Handle remove attachment
  const handleRemoveAttachment = (id) => {
    setFormData(prev => ({
      ...prev,
      attachments: prev.attachments.filter(att => att.id !== id)
    }));
  };
  
  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!formData.subject.trim()) {
      errors.subject = 'Subject is required';
    }
    
    if (!formData.customerName.trim()) {
      errors.customerName = 'Customer name is required';
    }
    
    if (!formData.customerEmail.trim()) {
      errors.customerEmail = 'Customer email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.customerEmail)) {
      errors.customerEmail = 'Email is invalid';
    }
    
    if (!formData.category) {
      errors.category = 'Category is required';
    }
    
    if (!formData.description.trim()) {
      errors.description = 'Description is required';
    }
    
    return errors;
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      
      // Scroll to first error
      const firstErrorElement = document.querySelector('.text-red-600');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      return;
    }
    
    setSaving(true);
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Navigate back to the ticket list or detail page
      navigate(isEditing ? `/crm/support/${ticketId}` : '/crm/support');
    } catch (error) {
      console.error('Error saving ticket:', error);
      setFormErrors({ submit: 'An error occurred while saving. Please try again.' });
    } finally {
      setSaving(false);
    }
  };
  
  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }
  
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex items-center">
          <button 
            type="button"
            onClick={() => navigate('/crm/support')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center mr-4"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back
          </button>
          <h2 className="text-lg font-medium text-gray-900">
            {isEditing ? 'Edit Support Ticket' : 'Create Support Ticket'}
          </h2>
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        {/* Form Error Message */}
        {formErrors.submit && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
            <div className="flex">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <p>{formErrors.submit}</p>
            </div>
          </div>
        )}
        
        {/* Ticket Subject */}
        <div className="mb-6">
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
            Subject <span className="text-red-500">*</span>
          </label>
          <input
            id="subject"
            name="subject"
            type="text"
            className={`block w-full rounded-lg border ${
              formErrors.subject ? 'border-red-300' : 'border-gray-300'
            } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
            value={formData.subject}
            onChange={handleInputChange}
            placeholder="Brief description of the issue"
          />
          {formErrors.subject && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.subject}
            </p>
          )}
        </div>
        
        {/* Ticket Details */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Category */}
          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
              Category <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Tag size={18} className="text-gray-400" />
              </div>
              <select
                id="category"
                name="category"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.category ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.category}
                onChange={handleInputChange}
              >
                <option value="">Select Category</option>
                {categoryOptions.map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
            {formErrors.category && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.category}
              </p>
            )}
          </div>
          
          {/* Priority */}
          <div>
            <label htmlFor="priority" className="block text-sm font-medium text-gray-700 mb-1">
              Priority
            </label>
            <div className="flex space-x-2">
              {priorityOptions.map((option) => (
                <label 
                  key={option.id}
                  className={`flex-1 flex items-center justify-center px-4 py-2 border rounded-lg cursor-pointer ${
                    formData.priority === option.id 
                      ? `bg-${option.color}-100 border-${option.color}-300 text-${option.color}-800` 
                      : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
                  }`}
                >
                  <input
                    type="radio"
                    name="priority"
                    value={option.id}
                    className="sr-only"
                    checked={formData.priority === option.id}
                    onChange={handleInputChange}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>
        </div>
        
        {/* Customer Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Customer Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Customer Name */}
          <div>
            <label htmlFor="customerName" className="block text-sm font-medium text-gray-700 mb-1">
              Customer Name <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <User size={18} className="text-gray-400" />
              </div>
              <input
                id="customerName"
                name="customerName"
                type="text"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.customerName ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.customerName}
                onChange={handleInputChange}
                placeholder="Full name"
              />
            </div>
            {formErrors.customerName && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.customerName}
              </p>
            )}
          </div>
          
          {/* Customer Email */}
          <div>
            <label htmlFor="customerEmail" className="block text-sm font-medium text-gray-700 mb-1">
              Customer Email <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail size={18} className="text-gray-400" />
              </div>
              <input
                id="customerEmail"
                name="customerEmail"
                type="email"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.customerEmail ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.customerEmail}
                onChange={handleInputChange}
                placeholder="email@example.com"
              />
            </div>
            {formErrors.customerEmail && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.customerEmail}
              </p>
            )}
          </div>
          
          {/* Customer Phone */}
          <div>
            <label htmlFor="customerPhone" className="block text-sm font-medium text-gray-700 mb-1">
              Customer Phone
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Phone size={18} className="text-gray-400" />
              </div>
              <input
                id="customerPhone"
                name="customerPhone"
                type="tel"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.customerPhone}
                onChange={handleInputChange}
                placeholder="+1 (555) 123-4567"
              />
            </div>
          </div>
          
          {/* Company */}
          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">
              Company
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Building size={18} className="text-gray-400" />
              </div>
              <input
                id="company"
                name="company"
                type="text"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.company}
                onChange={handleInputChange}
                placeholder="Company name"
              />
            </div>
          </div>
        </div>
        
        {/* Ticket Assignment */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Ticket Assignment</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Status */}
          <div>
            <label htmlFor="status" className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Clock size={18} className="text-gray-400" />
              </div>
              <select
                id="status"
                name="status"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.status}
                onChange={handleInputChange}
              >
                {statusOptions.map((status) => (
                  <option key={status.id} value={status.id}>{status.label}</option>
                ))}
              </select>
            </div>
          </div>
          
          {/* Assigned To */}
          <div>
            <label htmlFor="assignedTo" className="block text-sm font-medium text-gray-700 mb-1">
              Assigned To
            </label>
            <select
              id="assignedTo"
              name="assignedTo"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.assignedTo}
              onChange={handleInputChange}
            >
              <option value="">Unassigned</option>
              {staffMembers.map((staff) => (
                <option key={staff.id} value={staff.id}>{staff.name}</option>
              ))}
            </select>
          </div>
          
          {/* Due Date */}
          <div>
            <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700 mb-1">
              Due Date
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar size={18} className="text-gray-400" />
              </div>
              <input
                id="dueDate"
                name="dueDate"
                type="date"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.dueDate}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        
        {/* Description */}
        <div className="mb-6">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            id="description"
            name="description"
            rows="5"
            className={`block w-full rounded-lg border ${
              formErrors.description ? 'border-red-300' : 'border-gray-300'
            } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Detailed description of the issue..."
          />
          {formErrors.description && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.description}
            </p>
          )}
        </div>
        
        {/* Attachments */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Attachments
          </label>
          
          <div className="mb-3">
            <input
              type="file"
              id="file-upload"
              className="hidden"
              onChange={handleFileUpload}
              multiple
            />
            <label 
              htmlFor="file-upload" 
              className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <Upload size={16} className="mr-2" />
              Upload Files
            </label>
            <p className="mt-1 text-xs text-gray-500">
              You can upload multiple files. Maximum size per file: 10MB.
            </p>
          </div>
          
          {formData.attachments.length > 0 && (
            <div className="bg-gray-50 p-3 rounded-lg border border-gray-200">
              <p className="text-xs font-medium text-gray-700 mb-2">Attached Files:</p>
              <div className="flex flex-wrap gap-2">
                {formData.attachments.map((attachment) => (
                  <div key={attachment.id} className="bg-white rounded p-2 border flex items-center">
                    <span className="text-sm truncate max-w-[200px]">{attachment.name}</span>
                    <span className="text-xs text-gray-500 ml-2">{attachment.size}</span>
                    <button 
                      type="button"
                      onClick={() => handleRemoveAttachment(attachment.id)}
                      className="ml-2 text-gray-400 hover:text-red-500"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={() => navigate('/crm/support')}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            disabled={saving}
          >
            Cancel
          </button>
          
          <button
            type="submit"
            disabled={saving}
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              saving ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
          >
            {saving ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <Save size={18} className="mr-2" />
                {isEditing ? 'Update Ticket' : 'Create Ticket'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TicketForm;